import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "md"
    },
    theme: {
        themes: {
            options: {customProperties: true},
            light: {
                // Generic theme overrides
                primary: "#e6e7e9",
                secondary: "#333333",
                // accent: '',
                // error: '',
                // info: '',
                // success: '',
                // warning: '',
                anchor: "#8c9eff",
                background: "#e6e7e9",
                // customer: "#5f80c5",
                // Main Blues
                mainDarkBlue: "#140049",
                mainBlue: "#4e7ac7",
                mainMediumBlue: "#7fb2f0",
                mainLightBlue: "#add57f",
                // Main Other
                mainTeal: '#00ffee',
                mainGrey: '#e6e7e8',
                mainDark: '#21303e',
                // Crisis Response Palette
                purple: '#5e005e',
                hibiscus: '#ab2f52',
                burntOrange: '#e55d4a',
                darkYellow: '#ffaf53',
                // Evaluations Palette

            }
        }
    }
});
