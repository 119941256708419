<template>
  <v-dialog v-model="localShow" persistent max-width="650">
    <v-card>
      <v-card-title>
        {{ surveyItem.survey_name }}
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-card-title>
      <v-slide-x-transition mode="out-in">
        <v-card v-if="!showFinalSlide" flat :key="currentQuestionIndex">
          <v-card-title class="customer--text text-break">
            {{ currentQuestion.question }}
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!-- Questions -->
              <v-row>
                <v-col>
                  <!-- Single Answer -->
                  <v-radio-group
                    v-if="currentQuestion.question_type === 'single'"
                    v-model="selectedAnswer"
                  >
                    <v-radio
                      color="customer"
                      v-for="option in currentQuestion.options"
                      :key="option.id"
                      :label="`${option.sequence}: ${option.description}`"
                    >
                    </v-radio>
                  </v-radio-group>
                  <!-- Multi Answer -->
                  <template v-if="currentQuestion.question_type === 'multi'">
                    <template v-for="option in currentQuestion.options">
                      <v-checkbox
                        :key="option.id"
                        v-model="selectedMultiAnswer"
                        :value="option"
                        color="customer"
                        :label="`${option.sequence}: ${option.description}`"
                      >
                      </v-checkbox>
                    </template>
                  </template>
                  <!-- Rank Answer -->
                  <template v-if="currentQuestion.question_type === 'rank'">
                    <v-slider
                      v-model="selectedAnswer"
                      ticks="always"
                      :tick-labels="rankRange"
                      :min="rankMin"
                      :max="rankMax"
                      step="1"
                      color="customer"
                      track-color="black"
                      track-fill-color="customer"
                    ></v-slider>
                    <p class="customer--text text-center">
                      {{ currentQuestion.options[0].description }}
                    </p>
                  </template>
                </v-col>
              </v-row>

              <!-- Comments -->
              <v-row v-if="currentQuestion.comments">
                <v-col>
                  <v-card flat>
                    <v-card-text>
                      <v-form ref="commentForm">
                        <v-text-field
                          v-model="comment"
                          outlined
                          :label="currentQuestion.comment_label"
                          color="customer"
                          :rules="commentRules"
                          @keyup.native.enter="disableSubmit ? '' : recordAnswer()"
                          autocomplete="off"
                        >
                        </v-text-field>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!-- Submit -->
              <v-row>
                <v-col align="center" justify="center">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="customer"
                    @click="recordAnswer"
                    class="my-auto"
                    :disabled="disableSubmit"
                    >Submit
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-else flat>
          <v-card-title class="customer--text">Thank you!</v-card-title>
          <v-card-text>
            <slot></slot>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="customer"
                @click="closeDialog"
                class="mb-4"
              >Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-slide-x-transition>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Survey",
  props: {
    surveyItem: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  watch: {
    show: function(val) {
      this.localShow = val;
    },
  },

  computed: {
    currentQuestion: function() {
      return this.surveyItem.questions
        ? this.surveyItem.questions[this.currentQuestionIndex]
        : {};
    },

    commentRules: function () {
      const rules = []
      if (this.currentQuestion.comment_required) {
        rules.push(!!this.comment || "Comment is required.")
      }
      return rules
    },

    disableSubmit: function () {
      switch (this.currentQuestion.question_type) {
        case "multi":
          return this.selectedMultiAnswer.length < 1;
        default:
          return this.selectedAnswer.length < 1 && (this.currentQuestion.options?.length > 0);
      }
    },

    rankRange: function() {
      const result = [];
      for (let i = this.rankMin; i <= this.rankMax; i++) {
        result.push(i.toString());
      }
      return result;
    },

    rankMin: function() {
      return this.currentQuestion.options[0].rank_min;
    },

    rankMax: function() {
      return this.currentQuestion.options[0].rank_max;
    }
  },

  data: () => ({
    localShow: false,
    currentQuestionIndex: 0,
    progress: [],
    commentPresent: false,
    comment: "",
    showFinalSlide: false,
    selectedAnswer: '',
    selectedMultiAnswer: []
  }),

  methods: {
    recordAnswer() {
      if (this.currentQuestion.comment_required && this.comment.length < 1) {
        this.$refs.commentForm.validate()
        return
      }
      const result = [];
      const options = this.currentQuestion.options
      let emptyOptionsFallback
      switch (this.currentQuestion.question_type) {
        case "single":
          // Handle comment-only survey question
          emptyOptionsFallback = options.length === 0 ? 0 : options[this.selectedAnswer].id
          result.push({
            survey_option_id: emptyOptionsFallback,
            ranking: 0
          });
          break;
        case "multi":
          this.selectedMultiAnswer.forEach(answer => {
            result.push({
              survey_option_id: answer.id,
              ranking: 0
            });
          });
          break;
        case "rank":
          result.push({
            survey_option_id: this.currentQuestion.options[0].id,
            ranking: this.selectedAnswer
          });
          break;
      }
      // console.log("selected answer data", result);
      this.progress.push({
        survey_question_id: this.currentQuestion.id,
        comments: this.comment,
        options: result
      });
      this.nextQuestion();
    },

    nextQuestion() {
      this.selectedAnswer = '';
      this.selectedMultiAnswer = [];
      this.comment = "";
      if (this.currentQuestionIndex + 1 < this.surveyItem.questions.length) {
        this.currentQuestionIndex += 1;
      } else {
        this.$emit("survey-complete", this.surveyItem, this.progress);
        this.showFinalSlide = true;
      }
    },

    closeDialog() {
      // return training, recorded progress, and completion status
      this.$emit("close-survey");
    }
  }
};
</script>

<style scoped></style>