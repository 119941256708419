<template>
  <v-menu
      ref="menu"
      v-model="localShow"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
      @input="blurValidation"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="formattedDate"
          :name="label"
          :disabled="disabled"
          background-color="grey lighten-5"
          outlined
          color="mainBlue"
          :error-messages="v.errors[0]"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      >
        <template v-slot:label>
          {{ label }} <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}</span>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="hibiscus"
          @click="cancelDatePicker"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="mainBlue"
          @click="okClicked()"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "datepicker",
  props: {
    input: {
      type: String,
      required: false,
      default: ''
    },

    v: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    quiet: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString()
        .substr(0, 10),
    localShow: false
  }),
  computed: {

    formattedDate() {
      if ([null, undefined, ''].includes(this.input)) { return null }
      const [year, month, day] = this.input.split('-')
      return `${month}/${day}/${year}`
    }
  },
  methods: {
    okClicked() {
      this.$emit('date-selected', this.date)
      this.localShow = false
    },

    cancelDatePicker() {
      this.localShow = false
      this.$emit('validate')
    },

    blurValidation(val) {
      if (!val) {
        this.$emit('validate')
      }
    }
  }
}
</script>

<style scoped>

</style>