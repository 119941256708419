<template>
  <div>
    <styled-background :image="currentBackgroundImage" opacity="35%"/>
    <v-card class="mx-auto" width="90%" :loading="loading">
      <v-container>
        <template>
          <v-list-item :key="curatedTraining.name" readonly>
            <v-list-item-avatar tile size="75">
              <img :src="getImageSource(curatedTraining.featured_image)"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-col>
                <p class="h3 text-color">{{ curatedTraining.name }}</p>
                <p
                    v-if="isCertifiedTraining && ['training'].includes(curatedTraining.entity_type)"
                    class="subtitle-1 text-color"
                >
                  {{ getCompletionText(curatedTraining) }}
                </p>
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </template>
        <p id="text-color-subtitle" class="text-justify subtitle-2 pr-2 pl-2">
          {{ curatedTraining.description }}
        </p>
        <v-divider/>

        <template v-for="(trainingGroup, index) in trainingTypeGroups">
          <training-list-item
              :key="index + 100"
              :training-group="trainingGroup"
              :is-certified-training="isCertifiedTraining"
              :entity-type="curatedTraining.entity_type"
              :loading="loading"
              @training-tapped="trainingTapped"
          ></training-list-item>
        </template>
      </v-container>
    </v-card>
    <!-- Video Insert -->
    <v-dialog
        persistent
        :key="videoKey"
        v-model="videoDialog"
        transition="dialog-bottom-transition"
        overlay-opacity="0.80"
    >
      <VideoPage
          @done="videoDone"
          :training="selectedTraining"
          :isCertified="isCertifiedTraining"
          :parent-id="curatedTraining.product_id"
      ></VideoPage>
    </v-dialog>
    <!-- PDF Insert -->
    <PDFViewer
        v-on:pdf-dialog-value="setPDFDialog"
        :show="showPDF"
        @pdf-done="pdfDone"
        :url="pdfURL"
    ></PDFViewer>
    <!-- Questions Insert -->
    <Questions
        lazy
        :key="questionsKey"
        :show="questionsActive"
        :question-item="questionsItem"
        @questions-complete="markTestComplete"
        @close-test="closeTest"
    ></Questions>
    <!-- Surveys Insert -->
    <Survey
        :key="surveyKey"
        :show="surveyActive"
        :survey-item="surveyItem"
        @survey-complete="markSurveyComplete"
        @close-survey="closeSurvey"
    >
      <template>
        <v-progress-circular v-if="surveyLoading" color="primary"/>
        <template v-else>
          {{ surveyMessage }}
        </template>
      </template>
    </Survey>
    <!-- Snackbar -->
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout">
        Training complete!

        <template v-slot:action="{ attrs }">
          <v-btn
              color="secondary"
              text
              small
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import VideoPage from "@/components/videoPage.vue";
import PDFViewer from "@/components/pdfViewer.vue";
import TrainingListItem from "@/components/trainingListItem";
import Questions from "@/components/questions";
import Survey from "@/components/survey";
import StyledBackground from "@/components/styledBackground";

import {mapGetters} from "vuex";

export default {
  name: "product",

  components: {
    StyledBackground,
    TrainingListItem,
    VideoPage,
    PDFViewer,
    Questions,
    Survey
  },
  props: {
    trainingItem: {
      type: Object,
      required: false,
      default: null
    },

    backgroundImage: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    videoDialog: false,
    loading: false,
    selectedTraining: {},
    videoKey: 10000,
    pdfURL: null,
    showPDF: false,
    snackbar: false,
    timeout: 3000,
    autoSelectOnce: '',
    trainingTypeGroups: [],
    questionsActive: false,
    questionsItem: {},
    questionsKey: 1000,
    surveyKey: 2000,
    surveyActive: false,
    surveyItem: {},
    surveyFeedback: null,
    surveyLoading: false
  }),

  computed: {
    ...mapGetters(["user", 'lastProduct', 'storedImage']),
    curatedTraining() {
      return this.trainingItem === null ? this.lastProduct : this.trainingItem
    },
    currentBackgroundImage() {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },
    isCertifiedTraining() {
      return ![null, undefined, ""].includes(this.curatedTraining.status);
    },
    getVideosButtons() {
      return {videos: this.curatedTraining.videos};
    },
    getDocumentButtons() {
      return {documents: this.curatedTraining.documents};
    },
    getQuestionButtons() {
      return {questions: this.curatedTraining.questions};
    },
    getSurveysButtons() {
      return {surveys: this.curatedTraining.surveys};
    },
    getTreesButtons() {
      return {trees: this.curatedTraining.trees};
    },
    getCouponButtons() {
      return {coupons: this.curatedTraining.coupons};
    },
    getScanButtons() {
      return {scans: this.curatedTraining.scans};
    },
    surveyMessage() {
      return this.surveyFeedback?.message || "This Survey has been completed. You may now close this window."
    }
  },

  watch: {
    questionsActive() {
      if (!this.questionsActive) {
        this.questionsItem = {};
        this.questionsKey += 1;
      }
    },

    surveyActive() {
      if (!this.surveyActive) {
        this.surveyItem = {};
        this.surveyKey += 1;
      }
    }
  },

  beforeMount() {
    if (this.trainingItem) {
      this.$store.dispatch('setLastProduct', this.trainingItem)
    }
    // Populate trainingTypeGroup
    this.trainingTypeGroups = [
      this.getVideosButtons,
      this.getDocumentButtons,
      this.getSurveysButtons,
      this.getQuestionButtons
      // this.getScanButtons,
      // this.getCouponButtons,
      // this.getTreesButtons,
    ];
  },

  mounted() {
    // console.log('starting training item', this.curatedTraining)
    // Set up auto select for single item trainings
    const trainingSum = this.calculateTrainingSum()
    if (trainingSum === 1) {
      switch (this.autoSelectOnce) {
        case 'documents':
          this.openAsset(this.getDocumentButtons.documents[0])
          break
        case "videos":
          this.playVideo(this.getVideosButtons.videos[0]);
          break;
        case "questions":
          this.openTest(this.getQuestionButtons.questions[0]);
          break;
        case "surveys":
          this.openSurvey(this.getSurveysButtons.surveys[0]);
          break;
      }
    }

    // Check edge case for completed training not marked complete
    if (this.isCertifiedTraining) {
      let incompleteCount = 0;
      // add more when implementing surveys, quizzes, etc.
      this.trainingTypeGroups.forEach(type => {
        incompleteCount += this.checkAllComplete(Object.values(type)[0]);
      });
      if (incompleteCount === 0) {
        // console.log("checking training for completion");
        // TrainingService.checkTrainingStatus(
        //   this.$store.state.user.userId,
        //   this.curatedTraining.product_id
        // );
      } else {
        console.log("incomplete training: ", incompleteCount);
      }
    }
  },

  methods: {
    calculateTrainingSum() {
      let sum = 0
      this.trainingTypeGroups.forEach(group => {
        Object.keys(group).forEach(key => {
          if (group[key]) {
            let count = group[key].length
            sum += count
            if (count === 1) {
              this.autoSelectOnce = key
            }
          }
        })
      })
      return sum
    },

    videoDone() {
      this.videoDialog = false;
      this.selectedTraining = {};
      this.videoKey += 1;
    },

    pdfDone() {
      this.showPDF = false;
    },

    async trainingTapped(type, training) {
      // console.log("training tapped", type, training);
      let loadedTraining = training
      switch (type) {
        case "videos":
          this.playVideo(training);
          break;
        case "questions":
          this.openTest(training);
          break;
        case "surveys":
          // survey_id exists in full survey details. No survey_id means we have to make a call to FLX to get
          // the full survey details.
          if (!training.survey_id) {
            this.loading = true
            loadedTraining = await this.user.getSurvey(training.id)
            this.loading = false
          }
          this.openSurvey(loadedTraining);
          break;
        default:
          this.openAsset(training);
      }
    },

    getCompletionText(training) {
      if (training.complete === true) {
        return "Completed on: " + training.date_completed;
      } else {
        return "Complete by: " + training.required_thru;
      }
    },

    playVideo(training) {
      this.selectedTraining = training;
      this.videoDialog = true;
    },

    // Because many sites use X-FRAME-OPTIONS: SAMEORIGIN (or similar), loading assets in an
    // iframe continues to fail. Therefore, this pushes the url of any web assets to a new tab.
    openAsset(training) {
      if (training.format === "web") {
        // Mark training complete
        this.markTrainingComplete(training, training.file_id, "file");
        // Update analytics
        this.user.postViewedAnalytics(training.file_id)
        // AnalyticService.trainingFileViewed(
        //   training.file_id,
        //   this.$store.state.user.userId
        // );

        // Open open web asset.
        let destination = training.file_url
        if (training.file_id === 603) { // add code for bouncing back (product_file_id=603)
          destination = `${training.file_url}${this.user.caseCode}`
        }
        window.open(destination, "_blank");
      } else {
        this.openPDF(training);
      }
    },

    openPDF(training) {
      this.pdfURL = training.file_url;
      this.showPDF = true;
      this.markTrainingComplete(training, training.file_id, "file");

      // Update analytics
      this.user.postViewedAnalytics(training.file_id)
      // AnalyticService.trainingFileViewed(
      //   training.file_id,
      //   this.$store.state.user.userId
      // );
    },

    setPDFDialog(val) {
      this.showPDF = val;
    },

    getImageSource(record) {
      return ["", null, undefined].includes(record) ? require("@/assets/menuImages/path-to-enlightenment.png") : record;
    },

    openTest(training) {
      this.questionsItem = training;
      this.questionsActive = true;
    },

    closeTest() {
      this.questionsActive = false;
    },

    markTestComplete(training, result) {
      console.log('markTestComplete', training, result)
      //   if (!training.complete) {
      //     let quizInfo = {
      //       testId: training.test_id,
      //       employeeNumber: this.$store.state.user.userId,
      //       productId: this.curatedTraining.product_id,
      //       result: result
      //     };
      //     // TrainingService.markQuizComplete(quizInfo).then(() => {
      //     //   // console.log('mark quiz complete result', result)
      //     //   if (this.isCertifiedTraining && !training.complete) {
      //     //     this.snackbar = true;
      //     //   }
      //     //   training.complete = true;
      //     // });
      //   }
    },

    openSurvey(training) {
      this.surveyFeedback = null
      // console.log("open survey", training);
      this.surveyItem = training;
      this.surveyActive = true;
    },

    closeSurvey() {
      // console.log("close survey");
      this.surveyActive = false;
    },

    async markSurveyComplete(training, result) {
      // console.log('markSurveyComplete', training, result)
      // console.log('survey parent', this.curatedTraining)
      if (this.curatedTraining.standAloneSurvey) {
        this.surveyLoading = true
        this.surveyFeedback = await this.user.postStandAloneSurvey(result)
        this.surveyLoading = false
      }

      //   // console.log("survey complete", training, JSON.parse(JSON.stringify(result)));
      //   const surveyInfo = {
      //     questions: result,
      //     productId: this.curatedTraining.product_id,
      //     employeeId: this.$store.state.user.userId
      //   };
      //   // TrainingService.markSurveyComplete(surveyInfo).then(() => {
      //   //   if (this.isCertifiedTraining && !training.complete) {
      //   //     this.snackbar = true;
      //   //   }
      //   // });
    },

    markTrainingComplete(training, itemId, type) {
      console.log('markTrainingComplete', training, itemId, type)
      //   if (!training.complete) {
      //     const trainingInfo = {
      //       type: type,
      //       fileId: itemId,
      //       employeeId: this.$store.state.user.userId,
      //       parentId: this.curatedTraining.product_id
      //     };
      //     // this.$store.dispatch("markTrainingComplete", trainingInfo).then(() => {
      //     //   if (this.isCertifiedTraining && !training.complete) {
      //     //     this.snackbar = true;
      //     //   }
      //     //   training.complete = true;
      //     // });
      //   }
    },

    // Returns the number of incomplete items in the group
    checkAllComplete(itemGroup) {
      const incompleteCount = itemGroup.filter(item => !item.complete).length;
      // console.log("incomplete count from method: ", incompleteCount);
      return incompleteCount;
    }
  }
};
</script>

<style scoped>
.v-list-item.row {
  margin-top: auto;
  margin-bottom: auto;
}
</style>