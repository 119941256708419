<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" overlay-opacity="0.80">
    <v-container>
      <div id="pdf-page">
        <v-row dense align="center" justify="center">
          <v-col>
            <v-row align="center" justify="center">
              <v-col>
                <iframe class="pdf-frame" :src="url"></iframe>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-content-center">
                <v-btn block tile color="#eee" class="mx-auto"  @click="closePDF()">Done</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-dialog>
</template>
<script>
export default {
  name: "PDFViewer",
  props: ["url", "show"],
  data: () => ({
    dialog: false
  }),
  methods: {
    closePDF() {
      this.$emit("pdf-done");
    }
  },
  watch: {
    show: function(val) {
      this.dialog = val;
    },
    dialog: function(val) {
      this.$emit("pdf-dialog-value", val);
    }
  }
};
</script>
<style scoped>
#pdf-page {
  position: relative;
  width: 100%;
}

.pdf-frame {
  width: 95vw;
  height: 70vh;
  max-width: 100% !important;
  max-height: 80% !important;
  padding-bottom: 0;

}
</style>
