<template>
  <validation-provider :mode="mode" :name="label" :rules="rules" v-slot:default="v">
    <v-text-field
        v-if="type === 'number'"
        v-model.number="input.value"
        :name="label"
        :placeholder="placeholder"
        :disabled="disabled"
        type="number"
        outlined
        background-color="grey lighten-5"
        color="mainBlue"
        :error-messages="v.errors[0]"
    >
      <template v-slot:label>
        {{ label }} <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}</span>
      </template>
    </v-text-field>
    <v-text-field
        v-else-if="type === 'email'"
        v-model="input.value"
        :name="label"
        :placeholder="placeholder"
        :disabled="disabled"
        type="email"
        outlined
        background-color="grey lighten-5"
        color="mainBlue"
        :error-messages="v.errors"
    >
      <template v-slot:label>
        {{ label }} <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}</span>
      </template>
    </v-text-field>
    <v-select
        v-else-if="type === 'select'"
        v-model="input.value"
        :name="label"
        :placeholder="placeholder"
        :disabled="disabled || items.length === 0"
        :items="items"
        item-text="name"
        item-value="id"
        outlined
        background-color="grey lighten-5"
        color="mainBlue"
        :error-messages="v.errors"
    >
      <template v-slot:label>
        {{ label }} <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}</span>
      </template>
    </v-select>
    <v-combobox
        v-else-if="type === 'combo'"
        v-model="input.value"
        :name="label"
        :placeholder="placeholder"
        :disabled="disabled"
        :items="items"
        outlined
        background-color="grey lighten-5"
        color="mainBlue"
        :error-messages="v.errors"
        clearable
        clear-icon="mdi-close"
        @click:clear="v.validate()"
    >
      <template v-slot:label>
        {{ label }} <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}</span>
      </template>
    </v-combobox>
    <v-checkbox
        v-else-if="type === 'checkbox'"
        v-model="input.value"
        :name="label"
        :disabled="disabled"
        :input-value="checkboxFalseValue(v.required)"
        color="mainBlue"
        :error-messages="v.errors"
        :required="v.required"
    >
      <template v-if="input.terms" v-slot:label>
        <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}&nbsp;</span>
        <div>I accept the <a @click.stop target="_blank" href="https://r3-vtrainer.herokuapp.com/terms_conditions">
          Terms and Conditions</a>.
        </div>
      </template>
      <template v-else v-slot:label>
        <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}&nbsp;</span> {{ label }}
      </template>
    </v-checkbox>
    <v-text-field
        v-else-if="type === 'password'"
        type="password"
        v-model="input.value"
        :name="label"
        :placeholder="placeholder"
        :disabled="disabled"
        background-color="grey lighten-5"
        outlined
        color="mainBlue"
        :error-messages="v.errors[0]"
    >
      <template v-slot:label>{{ label }} <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}</span>
      </template>
    </v-text-field>
    <datepicker
        v-else-if="type === 'date'"
        v-model="input.value"
        :input="input.value"
        :label="label"
        :disabled="disabled"
        :v="v"
        :quiet="quiet"
        @date-selected="input.value = $event"
        @validate="v.validate()"
    ></datepicker>
    <v-text-field
        v-else
        type="text"
        v-model="input.value"
        :name="label"
        :placeholder="placeholder"
        :disabled="disabled"
        background-color="grey lighten-5"
        outlined
        color="mainBlue"
        :error-messages="v.errors[0]"
    >
      <template v-slot:label>
        {{ label }} <span class="burntOrange--text">{{ (v.required && !quiet) ? '*' : '' }}</span>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
import {ValidationProvider} from "vee-validate"
import Datepicker from "./datepicker";

export default {
  name: "flxInput",
  components: {
    Datepicker,
    ValidationProvider
  },
  props: {
    input: {
      type: Object,
      required: true
    },

    quiet: {
      type: Boolean,
      required: false,
      default: false
    },

    // Options include 'eager' (default), 'lazy', 'aggressive', and 'passive'
    mode: {
      type: String,
      required: false,
      default: 'eager'
    }
  },

  data: () => ({}),

  computed: {
    type: function () {
      return this.input.type || 'text'
    },

    label: function () {
      return this.input.label || ''
    },

    items: function () {
      return this.input.selectOptions || []
    },

    placeholder: function () {
      return this.input.placeholder || ''
    },

    rules: function () {
      return this.input.rules ? this.input.rules.join('|') : ''
    },

    disabled: function () {
      return this.input.disabled || false
    },
  },

  watch: {},

  updated() {
  },

  methods: {

    checkboxFalseValue(required) {
      if (required && this.input.value === false) {
        this.input.value = null
      }
      return this.input.value
    }
  }
}
</script>

<style scoped>

</style>