<template>
  <v-card flat style="background-color: transparent;" class="mx-auto">
    <!-- Date Filters -->
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <flx-input :input="startDate"/>
        </v-col>
        <v-col cols="12" md="6">
          <flx-input :input="endDate"/>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-btn
            @click="clearFilters"
            color="mainDarkBlue"
            style="color: white;"
            :disabled="startDate.value === '' && endDate.value === ''"
        >Clear Filters
        </v-btn>
      </v-row>
    </v-container>

    <!-- Detailed List -->
    <v-list three-line>
      <template v-for="item in filteredShifts">
        <v-list-item :key="item.id" ripple @click="itemClicked(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item.site_city }}, {{ item.site_state_attributes.description }}</v-list-item-title>
            <v-list-item-subtitle v-text="`${resolveStartTime(item)} ${item.time_zone_attributes.name}`" />
            <v-list-item-subtitle>
              {{ item.location_name }}
              <span v-if="item.location_name && item.location_description">-</span>
              {{ item.location_description }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon class="my-auto">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </v-list>
    <incident-details
        :shift="selectedShift"
        :incident-type="incidentType"
        :show="showDetails"
        :case-attributes="caseAttributes"
        @shift-details="showDetails = $event"
    />
  </v-card>
</template>

<script>
import FlxInput from "../flxInput";
import IncidentDetails from "../incidentDetails";

export default {
  name: "shifts",
  components: {
    IncidentDetails,
    FlxInput
  },

  props: {
    incidentType: {
      type: String,
      required: false,
      default: ''
    },

    shifts: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },

    caseAttributes: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },

  computed: {
    filteredShifts() {
      const startDate = this.$moment(this.startDate.value)
      const endDate = this.$moment(this.endDate.value)
      return this.shifts.filter(shift => {
        let keep = true
        const testDate = this.$moment(shift.start_time)
        if (startDate.isValid() && endDate.isValid()) {
          keep = testDate.isAfter(startDate) && testDate.isBefore(endDate)
        } else if (startDate.isValid()) {
          keep = testDate.isAfter(startDate)
        } else if (endDate.isValid()) {
          keep = testDate.isBefore(endDate)
        }
        return keep
      })
    },
  },

  data: () => ({
    startDate: {
      name: 'startDate',
      label: 'Start Date',
      type: 'date',
      value: ''
    },
    endDate: {
      name: 'endDate',
      label: 'End Date',
      type: 'date',
      value: ''
    },
    showDetails: false,
    selectedShift: {},
    shiftDialogKey: 0
    // line1: '',
    // line2: '',
    // line3: ''
  }),

  watch: {
    showDetails(val) {
      if (!val) {
        this.selectedShift = {}
      }
    }
  },

  methods: {

    clearFilters() {
      this.startDate.value = ''
      this.endDate.value = ''
    },

    resolveStartTime(item) {
      if (Object.keys(item).length > 0) {
        const start = this.$moment(item.start_time).tz(item.time_zone_attributes.canonical_identifier)
        const date = start?.format('L') || ''
        const time = start?.format('LT') || ''
        return `${date} ${time}`
      }
      return ''
    },

    itemClicked(item) {
      this.selectedShift = item
      this.shiftDialogKey += 1
      this.showDetails = true
      // console.log('item clicked', this.showDetails, item)
    }
  }
}
</script>

<style scoped>

</style>