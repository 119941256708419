<template>
  <v-container>
    <v-row v-if="showReport" dense>
      <v-col cols="12" md="7" lg="8" order="2" order-md="1">
        <v-card tile min-height="100%" width="100%">
          <v-card-title class="d-flex justify-center">Billable Hours</v-card-title>
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="reportData"
                :items-per-page="5"
                :sort-by="['billed']"
                :sort-desc="['true']"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="4" order="1" order-md="2">
        <v-card tile width="100%" height="100%" class="fill-height">
          <bar-chart
              style="max-height: 30vh; max-width: 30vh;"
              class="mx-auto"
              :chart-data="chartData"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card flat width="80%" min-height="51vh" class="mx-auto">
        <v-card-title class="d-flex justify-center">
          No Billable Hours report data found.
        </v-card-title>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "../charts/barChart";

export default {
  name: "billableHoursReport",
  components: {
    BarChart
  },
  props: {
    headers: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },

    reportData: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },

    chartData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },

  computed: {
    showReport() {
      return this.reportData.length > 0
    }
  }
}
</script>

<style scoped>

</style>