<template>
  <div>
    <v-container v-if="!oAuthHtml">
      <v-row style="max-width: 80vw; margin-left: auto; margin-right: auto;">
        <v-col cols="12" md="6" class="my-auto">

          <!-- QR Code Login -->
          <v-card flat color="mainGrey" :loading="loading">
            <template slot="progress">
              <v-progress-linear
                  color="mainBlue"
                  height="7"
                  indeterminate
              ></v-progress-linear>
            </template>
            <div v-if="!loading" style="height: 7px;"></div>
            <v-card-title class="text-h4 mb-3">Login</v-card-title>
            <v-card-subtitle class="mb-3">
              If on a mobile device use our scan feature to scan the provided QR code to access the system.
              Otherwise, enter the provided access code.
            </v-card-subtitle>
          </v-card>
          <validation-observer v-slot="{ invalid }">
            <v-form @submit.prevent="codeLogin(codeField.value)">
              <flx-input mode="aggressive" :quiet="true" :input="codeField"></flx-input>
              <v-row class="mb-4">
                <v-col>
                  <v-btn
                      color="mainMediumBlue"
                      class="d-inline-block"
                      tile
                      type="submit"
                      :disabled="invalid"
                      :loading="loading"
                  >Submit
                  </v-btn>
                  <v-btn
                      class="blue-outline d-inline-block ml-5"
                      @click="qrCode"
                      outlined
                  >Scan QR code
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>

          <!-- Leadership Login -->
          <v-card flat color="mainGrey">
            <v-card-title class="text-h5">Leadership Access</v-card-title>
          </v-card>
          <validation-observer v-slot="{ invalid }">
            <v-form @submit.prevent="credentialsLogin">
              <flx-input
                  v-for="(field, index) in loginFields"
                  :key="index"
                  :input="field"
                  mode="aggressive"
                  :quiet="true"
              ></flx-input>
              <v-btn color="mainMediumBlue" tile type="submit" :disabled="invalid" :loading="loading">Submit</v-btn>
            </v-form>
          </validation-observer>

          <v-btn class="blue-outline mt-6" @click="goGuest" outlined>Explore as a guest</v-btn>
        </v-col>
        <v-col class="d-none d-md-inline-flex" md="6">
          <v-img
              alt="R3SILIENCY Login"
              contain
              src="@/assets/login-image.png"
              transition="scale-transition"
          />
        </v-col>
      </v-row>
    </v-container>
    <div v-else v-html="oAuthHtml"></div>
  </div>
</template>

<script>

import flxInput from '../components/flxInput'
import {ValidationObserver} from "vee-validate"
import User from "../services/User";
import {mapGetters} from "vuex";

export default {
  name: "Login",
  components: {
    flxInput,
    ValidationObserver
  },
  data: () => ({
    loginFields: [],
    codeField: [],
    username: '',
    password: '',
    loading: false,
    newUser: null,
    oAuthHtml: null
  }),

  computed: {
    ...mapGetters(['user'])
  },
  watch: {
  },
  created() {
    if (this.user) {
      this.$store.dispatch('logout')
    }
    this.newUser = new User()
    this.loginFields = this.getLoginFields()
    this.codeField = {
      label: 'Enter Code',
      type: 'text',
      value: '',
      rules: ['required', 'min:4']
    }
  },

  mounted() {
  },

  beforeDestroy() {
    // document.removeEventListener('submit', this.storeServiceCode)
  },

  methods: {
    getLoginFields() {
      return [
        {
          name: 'username',
          label: 'Username',
          type: 'text',
          value: '',
          rules: ['required']
        }
      ]
    },

    async credentialsLogin() {
      this.loading = true
      const data = {}
      this.loginFields.forEach(obj => {
        data[obj.name] = obj.value
      })

      if (data['username']) {
        await this.newUser.startOauth(data['username']).then(data => {
          this.oAuthHtml = data.data
          const bootstrap = document.createElement('link')
          bootstrap.href = "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
          bootstrap.rel = 'stylesheet'
          document.head.appendChild(bootstrap)
          // document.open()
          // document.write(data.data)
          // document.close()
        }).then(() => {
          // const el = document.getElementById('data_attributes_password')
          // if (el.getAttribute('placeholder') === 'Code') {
          //   document.addEventListener('submit', this.storeServiceCode)
          // }
        })
      }
    },

    // storeServiceCode(e) {
    //   e.preventDefault()
    //   const code = document.getElementById('data_attributes_password').value
    //   window.$cookies.set('serviceRecipientCode', code)
    //   document.getElementById('signIn').submit()
    // },

    async codeLogin(code) {
      // console.log('code login pressed', code)
      if (code) {
        await this.newUser.startOauth(code).then(data => {
          this.oAuthHtml = data.data
          const bootstrap = document.createElement('link')
          bootstrap.href = "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
          bootstrap.rel = 'stylesheet'
          document.head.appendChild(bootstrap)
          // document.open()
          // document.write(data.data)
          // document.close()
        }).then(() => {
          // Automate code submit
          this.$nextTick()
          document.getElementById('signIn').submit()
        })
      }

    },

    /**
     * @description send user to qr code scanner
     */
    qrCode() {
      // console.log('qr code pressed')
      this.$router.push({name: "Scanner"})
    },

    /**
     * @description send user to self-registration
     */
    goGuest() {
      this.$router.push({name: 'Self Registration'})
    }
  }
};
</script>

<style scoped>
#signIn {
  display: block;
}
.menu-font {
  font-family: "Nobile", sans-serif;
}

.v-input__slot {
  background-color: white !important;
}

.link {
  cursor: pointer;
}

.blue-outline {
  border: 1px #7fb2f0 solid !important;
}
</style>
