<template>
  <div>
    <styled-background :image="currentBackgroundImage" opacity="35%"/>
    <v-card flat width="80%" class="mx-auto" style="background-color: transparent;">
      <v-card-title class="d-flex justify-center">{{ title }}</v-card-title>
      <v-card-subtitle class="d-flex justify-center my-1" style="text-transform: capitalize;">
        <div class="mx-2">
          <v-icon>mdi-alert-outline</v-icon>
          {{ incidentType }}
        </div>
        <div class="mx-2">
          <v-icon>mdi-calendar</v-icon>
          {{ incidentDate }}
        </div>
      </v-card-subtitle>
      <v-card-subtitle class="d-flex justify-center">
        <v-card tile width="100%" v-if="incidentDetails" class="pa-3">
          <div class="d-flex justify-center mb-3">Description</div>
          <v-card-text class="d-flex justify-center">{{ incidentDetails }}</v-card-text>
        </v-card>
      </v-card-subtitle>
      <v-card-text>
        <v-card tile>
          <v-tabs
              v-model="tab"
              fixed-tabs
              center-active
              show-arrows
              color="mainBlue"
              background-color="mainGrey"
          >
            <v-tabs-slider color="mainBlue"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, index) in items" :key="item">
              <shifts
                  v-if="index === 0"
                  :incident-type="incidentType"
                  :shifts="shiftAttributes"
                  :case-attributes="caseAttributes"
              />
              <what-to-expect v-if="index === 1" :whatToExpect="whatToExpect" :loading="WTELoading" />
              <connect-with-r3 v-if="index === 2" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StyledBackground from "../components/styledBackground";
import shifts from "../components/serviceLogistics/shifts"
import WhatToExpect from "../components/serviceLogistics/whatToExpect";
import ConnectWithR3 from "../components/serviceLogistics/connectWithR3";

export default {
  name: "Incident",
  components: {
    ConnectWithR3,
    WhatToExpect,
    StyledBackground,
    shifts
  },
  props: {
    incident: {
      type: Object,
      required: false,
      default: null
    },

    backgroundImage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    tab: null,
    items: ['SHIFTS', 'WHAT TO EXPECT', 'CONNECT WITH R3'],
    whatToExpect: [],
    WTELoading: false
  }),

  computed: {
    ...mapGetters(['user', 'storedImage', 'lastIncident', 'lastIncidentTab']),
    currentBackgroundImage() {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },
    currentIncident() {
      return this.incident === null ? this.lastIncident : this.incident
    },
    title() {
      let title = 'Title Unavailable'
      if (this.currentIncident.attributes?.case_attributes?.client_attributes) {
        title = this.currentIncident.attributes.case_attributes.client_attributes.name
      }
      return title
    },
    incidentType() {
      let description = 'Description Unavailable'
      if (this.currentIncident.attributes?.incident_type_attributes) {
        description = this.currentIncident.attributes.incident_type_attributes.description
      }
      return description
    },
    incidentDate() {
      const date = this.$moment(this.currentIncident.attributes.incident_date)
      return date.isValid() ? date.format("L") : ''
    },
    incidentDetails() {
      return this.currentIncident.attributes?.case_attributes?.description || ''
    },
    shiftAttributes() {
      return this.currentIncident.attributes?.shifts_attributes || []
    },
    caseAttributes() {
      return this.currentIncident.attributes?.case_attributes || {}
    }
  },

  mounted() {
    this.tab = this.lastIncidentTab
    this.getWhatToExpect()
  },

  updated() {
  },

  methods: {

    resolveTitle(listItem) {
      let title = 'Title Unavailable'
      if (listItem.attributes?.case_attributes?.client_attributes) {
        title = listItem.attributes.case_attributes.client_attributes.name
      }
      return title
    },

    async getWhatToExpect() {
      this.WTELoading = true
      this.whatToExpect = await this.user.getProps(
          `api/assets/r3_incident_training/${this.incidentType}/level${this.user.level}`
      ).then(response => {
        return response.products
      }).catch(err => {
        console.log('error', err)
      })
      this.WTELoading = false
    }
  }
};
</script>

<style scoped>
.menu-font {
  font-family: "Nobile", sans-serif;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 3px 3px 1px -2px rgba(0, 0, 0, 0.14),
  0px 2px 2px 0px rgba(100, 152, 66, 0.3), 0px 1px 5px 0px rgba(100, 152, 66, 0.25);
}
</style>
