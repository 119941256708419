<template>
  <div>
    <styled-background :image="currentBackgroundImage"></styled-background>
    <v-card flat style="background-color: transparent" width="80%" class="mx-auto">
      <v-card width="500" flat class="mx-auto" style="background: transparent;">
        <v-card-title class="d-flex justify-center">Contact Information</v-card-title>
        <v-card-subtitle class="text-center mt-3">
          We are here for you. Please let us know how we can be of assistance. Contact us.
        </v-card-subtitle>
      </v-card>
      <v-card width="80%" min-height="400" tile class="mx-auto">
        <v-divider/>
        <v-card-text>
          <!-- Contact Us Info -->
          <v-list v-if="contacts.contacts" three-line subheader>
            <template v-for="(group, groupKey) in contactGroups">
              <v-subheader :key="groupKey">{{ groupKey }} Contacts</v-subheader>
              <v-list-item v-for="(item, index) in group" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.phone_number">
                    <a :href="`tel:+1${item.phone_number}`">{{ item.phone_number }}</a>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.email">
                    <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon class="d-none d-sm-flex">
                  <a v-if="item.phone_number" :href="`tel:+1${item.phone_number}`">
                    <v-btn class="ma-2" outlined fab color="mainBlue">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </a>
                  <a v-if="item.email" :href="`mailto:${item.email}`">
                    <v-btn class="ma-2" outlined fab color="mainBlue">
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </a>
                </v-list-item-icon>
              </v-list-item>
              <v-divider :key="`${groupKey}contacts`"/>
            </template>
          </v-list>
          <!-- Website Info -->
          <v-list v-if="contacts.websites">
            <template v-for="(group, groupKey) in websiteGroups">
              <v-subheader :key="groupKey">{{ groupKey }} Online</v-subheader>
              <v-list-item v-for="(item, index) in group" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.description }}</v-list-item-title>
                  <v-list-item-subtitle v-if="item.web_url">
                    <a :href="item.web_url" target="_blank">{{ item.web_url }}</a>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon class="d-none d-sm-flex">
                  <a v-if="item.web_url" :href="item.web_url" target="_blank">
                    <v-btn class="ma-2" outlined fab color="mainBlue">
                      <v-icon>mdi-laptop</v-icon>
                    </v-btn>
                  </a>
                </v-list-item-icon>
              </v-list-item>
              <v-divider :key="`${groupKey}online`"/>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>

import styledBackground from "../components/styledBackground";
import {mapGetters} from "vuex";

export default {
  name: "Contact",
  components: {
    styledBackground
  },
  props: {
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    },

    contacts: {
      type: Object,
      required: false,
      default: Object
    }
  },
  data: () => ({
    // groups: {}
  }),

  computed: {
    ...mapGetters(["storedImage"]),

    currentBackgroundImage() {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },

    contactGroups() {
      const result = {}
      let lastGroup = ''
      if (this.contacts?.contacts) {
        this.contacts.contacts.forEach(obj => {
          // build keys
          if (obj.group.localeCompare(lastGroup) !== 0) {
            lastGroup = obj.group
          }
          // instantiate group array
          if (!result[lastGroup]) {
            result[lastGroup] = []
          }
          // populate group array
          result[lastGroup].push(obj)
        })
      }
      return result
    },

    websiteGroups() {
      const result = {}
      let lastGroup = ''
      if (this.contacts?.websites) {
        this.contacts.websites.forEach(obj => {
          // build keys
          if (obj.group.localeCompare(lastGroup) !== 0) {
            lastGroup = obj.group
          }
          // instantiate group array
          if (!result[lastGroup]) {
            result[lastGroup] = []
          }
          // populate group array
          result[lastGroup].push(obj)
        })
      }
      return result
    }
  },

  mounted() {
  },

  updated() {
  },

  methods: {}
};
</script>

<style scoped>
.menu-font {
  font-family: "Nobile", sans-serif;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 3px 3px 1px -2px rgba(0, 0, 0, 0.14),
  0px 2px 2px 0px rgba(100, 152, 66, 0.3), 0px 1px 5px 0px rgba(100, 152, 66, 0.25);
}
</style>
