import Comms from "../services/Communication";
// import mockups from "@/js/mocks";
import store from "../vuex/store"
import router from "../router";
import {uuid} from "vue-uuid"

export default class User {
    level = 0
    loggedIn = false
    caseCode = ''
    serviceRecipientCode = null

    constructor(savedUser = {}) {
        this.comms = new Comms()
        if (savedUser && Object.keys(savedUser).length > 0) {
            if (savedUser.caseCode) this.caseCode = savedUser.caseCode
            if (savedUser.deviceId) this.deviceId = savedUser.deviceId
            if (savedUser.level) this.level = savedUser.level
            this.loggedIn = true
        }

        this.__storeUser()
    }

    /********* GETTERS *********/

    get loggedIn() {
        return this.loggedIn
    }

    get level() {
        return this.level
    }

    get caseCode() {
        return this.caseCode
    }

    get materials() {
        return this.__getSupportMaterials()
    }

    get industries() {
        return this.__getIndustries()
    }

    get sources() {
        return this.__getSources()
    }

    /********* PUBLIC METHODS *********/

    /**
     * @description
     * Dynamic call to fill out various training details. Populated by the 'call' parameter from the MenuGroup item.
     * @param {String} url - the 'call' parameter from the MenuGroup item.
     * @returns {Promise<Object>} - menu item details, like search results, trainingItems, etc.
     */
    async getProps(url) {
        return await this.comms.get('flx', url, (status, response) => {
            return response
        }).then(response => {
            return response
        })
    }

    /**
     * @description
     * Get full survey details from unique ID
     * @param {Number} id - ID of survey to retrieve
     * @returns {Promise<Object>} - Full survey details
     */
    async getSurvey(id) {
        return await this.comms.get('flx',
            `api/surveys/get_survey/${id}`,
            (status, response) => {
                return response
            }).then(response => {
            return response
        })
    }

    /**
     * @description
     * Populates a list of incidents from the selected client
     * @param {String} clientId - ID of the client to retrieve list of incidents from
     * @returns {Promise<Object>} - Incident List belonging to the client
     */
    async getServiceLogisticsDetails(clientId) {
        return await this.comms.get('r3',
            `/v1/onsite_services?filter[records_for_client]=${clientId}`,
            (status, response) => {
                // console.log('service logistics details status', status, response)
                return response
            }).then(response => {
            return response.data
        }).catch((error, status) => {
            console.log('Error getting Service Logistics details from R3', error, status)
            return {}
        })
    }

    /**
     * @description
     * Get details for the shift's assigned specialist.
     * @param {String} shiftId - ID of the shift
     * @returns {Promise<Object>} - Specialist's details
     */
    async getSpecialistData(shiftId) {
        return this.comms.get('r3',
            `/v1/shifts/${shiftId}?include=specialist`,
            (status, response) => {
                return response
            }).then(response => {
            return response.included
        }).catch((error, status) => {
            console.log('Error getting specialist data from R3', error, status)
            return {}
        })
    }

    /**
     * @description
     * Get incident data for reporting during the selected date range for the selected client or all clients
     * @param {String} startDate - in YYYY-MM-DD format
     * @param {String} endDate - in YYYY-MM-DD format
     * @param {String} clientId - (optional) client ID string
     * @returns {Promise<Array>} - report data
     */
    async getReportIncidents(startDate, endDate, clientId = null) {
        let path
        if (clientId) {
            path = `/v1/onsite_services/incident_report?start_date=${startDate}&end_date=${endDate}&client_id=${clientId}`
        } else {
            path = `/v1/onsite_services/incident_report?start_date=${startDate}&end_date=${endDate}`
        }
        return await this.comms.get('r3',
            path,
            (status, response) => {
                return response
            }).then(response => {
            return response.data
        }).catch((error, status) => {
            console.log('Error getting report incident details from R3', error, status)
            return []
        })
    }

    /**
     * @description
     * Get utilization data for reporting during the selected date range for the selected client or all clients
     * @param {String} startDate - in YYYY-MM-DD format
     * @param {String} endDate - in YYYY-MM-DD format
     * @param {String} clientId - (optional) client ID string
     * @returns {Promise<Array>} - report data
     */
    async getReportUtilization(startDate, endDate, clientId = null) {
        let path
        if (clientId) {
            path = `/v1/onsite_services/utilization_report?start_date=${startDate}&end_date=${endDate}&client_id=${clientId}`
        } else {
            path = `/v1/onsite_services/utilization_report?start_date=${startDate}&end_date=${endDate}`
        }
        return await this.comms.get('r3',
            path,
            (status, response) => {
                return response
            }).then(response => {
            return response.data
        }).catch((error, status) => {
            console.log('Error getting report utilization details from R3', error, status)
            return []
        })
    }

    /**
     * @description
     * Get billable hours data for reporting during the selected date range for the selected client or all clients
     * @param {String} startDate - in YYYY-MM-DD format
     * @param {String} endDate - in YYYY-MM-DD format
     * @param {String} clientId - (optional) client ID string
     * @returns {Promise<Array>} - report data
     */
    async getReportBillableHours(startDate, endDate, clientId = null) {
        let path
        if (clientId) {
            path = `/v1/onsite_services/billable_hours_report?start_date=${startDate}&end_date=${endDate}&client_id=${clientId}`
        } else {
            path = `/v1/onsite_services/billable_hours_report?start_date=${startDate}&end_date=${endDate}`
        }
        return await this.comms.get('r3',
            path,
            (status, response) => {
                return response
            }).then(response => {
            return response.data
        }).catch((error, status) => {
            console.log('Error getting report billable hours details from R3', error, status)
            return []
        })
    }

    /**
     * @description
     * Post to FLX for product views analytics.
     * @param {Number} productId - Product ID (training) viewed
     * @returns {Promise<String>} - API response
     */
    async postViewedAnalytics(productId) {
        const payload = {
            'login': this.deviceId,
            'product_file_id': productId
        }
        return await this.comms.post('flx',
            'api/views/content/',
            payload,
            (status, response) => {
                // console.log('post viewed analytics status', status, response)
                return response
            }).then(response => {
            return response
        })
    }

    /**
     * @description
     * Posts to FLX the results of any support feedback surveys taken.
     * @param {Object} questions - results of the survey in an object containing survey_question_id, comments, and
     * options [survey_option_id, ranking]
     * @returns {Promise<Object>} - FLX api response
     */
    async postStandAloneSurvey(questions) {
        const results = {
            employee_id: this.level === 0 ? this.caseCode : this.deviceId,
            questions: questions
        }
        // console.log('posted results', post)
        return await this.comms.post('flx',
            'api/surveys/survey_results',
            results,
            (status, response) => {
                // console.log('survey', status, response)
                return response
            }).then(response => {
            return response
        })
    }

    registerLevel3RobinCall() {
        this.comms.registerLevel3RobinCall(this.deviceId, this.caseCode).then(r => r)
    }

    /********* PRIVATE SUPPORT METHODS *********/

    /**
     * @description Store or update user object in the vuex store.
     * @private
     */
    __storeUser() {
        store.dispatch('storeUser', this).then(r => r)
    }

    /**
     * @description Return curated list of applicable support materials based on the user's level.
     * @returns {Promise<Object>} - List of support materials
     * @private
     */
    async __getSupportMaterials() {
        let url
        switch (this.level) {
            case 0:
                url = 'api/assets/category/guest/'
                break;
            case 1:
                url = this.__buildMaterials('level1')
                break;
            case 2:
                url = this.__buildMaterials('level2')
                break;
            case 3:
                url = this.__buildLevel3Materials()
                break;
            default:
        }
        let materials = await this.comms.get('flx',
            url,
            (status, response) => {
                // console.log('status', status, response)
                return response
            }).then(response => {
            return response
        })
        return materials.products
    }

    /**
     * @description Using the access token returned by R3, decode the user's level.
     * @param token
     * @returns {number} level - The level of the user '0 (guest), 1, 2, 3'
     * @private
     */
    __assignLevel(token = null) {
        let level = 0
        if (token) {
            const decodedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
            if (decodedToken['sub'].includes('users')) {
                level = 1
                this.__fetchClients().then()
            } else if (decodedToken['sub'].includes('shift_contacts')) {
                level = 2
                this.__fetchClients().then()
            } else if (decodedToken['sub'].includes('service_recipients')) {
                level = 3
            } else {
                console.log('user level not defined', decodedToken)
            }
        }
        return level
    }

    /**
     * @description
     * Add customer and client information to the Support Materials available for levels 1 and 2
     * @param {String} level - Specify level1 or level2 endpoint
     * @returns {Promise<string>} - URI endpoint with specifics for the given level
     * @private
     */
    async __buildMaterials(level) {
        const url = `api/assets/category/${level}`
        const customers = await this.comms.get(
            'r3',
            '/v1/customers',
            (status, response) => {
                return response.data
            }
        ).catch(error => {
            console.log('Error retrieving customers', error.response?.status, error.response)
            return null
        })

        const clients = await this.comms.get(
            'r3',
            '/v1/clients',
            (status, response) => {
                return response.data
            }
        ).catch(error => {
            console.log('Error retrieving customers', error.response?.status, error.response)
            return null
        })

        if (customers && clients) {
            const customerString = this.__concatArrays(customers)
            const clientString = this.__concatArrays(clients)
            return `${url}?customers=${customerString}&clients=${clientString}`
        }
        return url
    }

    /**
     * @description
     * Concatenate a string of IDs from the given array of objects.
     * @param {Array} array - Array of objects with ids
     * @returns {string} - Concatenated string of comma-separated ids
     * @private
     */
    __concatArrays(array) {
        let result = ''
        for (let i = 0; i < array.length; i++) {
            if (result !== '') {
                result += ','
            }
            result += array[i].id
        }
        // console.log('concat result', result)
        return result
    }

    /**
     * @description
     * Gets specific case description information from R3 to add relevant support materials to the base-level
     * support materials.
     * @returns {Promise<String>} url - api endpoint with specifics for the given case code
     * @private
     */
    async __buildLevel3Materials() {
        // get onsite_services data
        const onsite_services = await this.comms.get('r3',
            "/v1/onsite_services",
            (status, response) => {
                // console.log('status', status, response)
                return response
            }).then(response => {
            if (!response.data[0].attributes) {
                return null
            }
            return {
                incident_type_id: response.data[0].attributes?.incident_type_id || null,
                case_client_id: response.data[0].attributes?.case_client_id || null,
                case_customer_id: response.data[0].attributes?.case_customer_id || null,
            }
        }).catch(error => {
            console.log('Error retrieving incident type id', error.response?.status, error.response)
            return null
        })
        if (onsite_services === null || onsite_services.incident_type_id === null) {
            return ''
        }

        // get incident attribute description
        const incident_attribute_description = await this.comms.get('r3',
            `/v1/incident_types/${onsite_services.incident_type_id}`,
            (status, response) => {
                // console.log('status', status, response)
                return response
            }).then(response => {
            return response.data.attributes?.description || null
        }).catch(error => {
            console.log('Error retrieving incident attribute description', error.response?.status, error.response)
            return null
        })
        if (incident_attribute_description === null) {
            return ''
        }

        let params = ''
        if (onsite_services.case_client_id && onsite_services.case_customer_id) {
            params = `?customers=${onsite_services.case_customer_id}&clients=${onsite_services.case_client_id}`
        }

        return `api/assets/category/level3/${incident_attribute_description}${params}`
    }

    /**
     * @description
     * Build the initial list of all client data, which sets up the list of client details for
     * service logistics and reporting.
     * @returns {Promise<Object>} - Service Logistics and reporting client list.
     * @private
     */
    async __fetchClients() {
        const result = await this.comms.get('r3',
            '/v1/clients',
            (status, response) => {
                // console.log('service logistics status', status, response)
                return response
            }).then(response => {
            return response
        }).catch((error, status) => {
            console.log('Error getting Service Logistics data from R3', error, status)
            return {}
        })
        await store.dispatch('setServiceLogisticsData', result?.data)
    }

    /**
     * Post login info to FLX for analytics
     * @private
     */
    __recordLogin() {
        const payload = {
            'login': this.caseCode,
            'device_token': this.deviceId,
            'app': 'r3siliency',
            'access': this.level === 0 ? 'guest' : `level${this.level}`,
            'operating_system': 'Web App'
        }
        this.comms.post('flx',
            'api/authorizations/user_audit/',
            payload,
            (status, response) => {
                // console.log('status', status, response)
                return response
            }).then(r => r).catch((error, status) => {
            console.log('error recording login:', error, status)
        })
    }

    /********* LOGIN METHODS *********/

    /**
     * @description
     * Uses local comms instance to start the Oauth process with R3. Moved here to keep comms out of the rest of
     * the code
     * @param {String} code - Login code or email
     * @returns {Promise<Object>} - Data to populate the 'document.write' that logs into R3 and redirects to our callback page
     */
    async startOauth(code) {
        return await this.comms.startOauth(code).then(data => {
            return data
        }).catch((error, status) => {
            console.log('Error in startOauth', error, status)
            return {}
        })
    }

    /**R
     * @description
     * Uses local comms instance to finish Oauth process with R3 after the callback is completed.
     * @param {String} hash - Hash token from R3 that is needed to finish the login process.
     * @returns {Object} - Data from R3 after login attempt that contains result data and session tokens.
     */
    async updateOauthWithHashAndGetTokens(hash) {
        return await this.comms.updateOauthWithHashAndGetTokens(hash).then(data => {
            return data
        }).catch((error, status) => {
            console.log('Error in updateOauth', error, status)
            return {}
        })
    }

    /**
     * @description
     * After the R3 login/callback, login the user locally and set the case-code, user level, available menu items, etc.
     * @param {String} token - Access token from R3 login process.
     * @returns {User|number}
     */
    login(token = null) {
        if (token) {
            // this.serviceRecipientCode = window.$cookies.get('serviceRecipientCode')
            this.caseCode = window.$cookies.get('caseCode')
            // window.$cookies.remove('serviceRecipientCode')
            window.$cookies.remove('caseCode')
            this.loggedIn = true // Used by the router to limit accessible pages.
            this.deviceId = uuid.v4().replaceAll('-', '')
            this.level = this.__assignLevel(token)
            this.__recordLogin()
            this.__storeUser()
            router.push({name: 'Menu'}).then(r => r)
            return this
            // return 200 // Modify for real call
        } else {
            return 401
        }
    }

    /**
     * @description
     * Takes data from self-registration to login as a prospect at guest level.
     * @param {Object} userData - Info from required fields in self-registration
     * @returns {Object} - Data returned from logging into R3
     */
    async guestLogin(userData) {

        const result = await this.comms.authorizeProspect(userData).then(result => {
            return result
        }).catch((error, status) => {
            console.log('Error authorizing prospect', error, status)
            return {}
        })
        if (result?.status === 201) {
            this.loggedIn = true // Used by the router to limit accessible pages.
            // this.deviceId = (uuid.v4()).replace('-', '')
            this.deviceId = uuid.v4().replaceAll('-', '')
            this.caseCode = result.data.data.attributes.email
            this.level = 0
            this.__recordLogin()
            this.__storeUser()
            router.push({name: 'Menu'}).then(r => r)
        } else {
            console.log('Prospect not created.', result)
        }
        return result
    }

    async authorizeRobin() {
        window.$cookies.set('user', JSON.stringify(store.getters.user), 60)
        window.$cookies.set('robinImage', store.getters.storedImage, 60)
        return await this.comms.authorizeRobin(this.deviceId, this.caseCode).then(data => {
            return data
        })
    }

    async handleRobinCallback(hash) {
        return await this.comms.handleRobinCallback(hash).then(data => {
            return data
        })
    }

    runLevel3Robin() {
        router.replace({name: 'Robin'}).then()
    }

    /********* SELF REGISTRATION CALLS *********/

    /**
     * Makes API call to FLX to return curated list of industries.
     * @returns {Promise<*>}
     * @private
     */
    async __getIndustries() {
        return await this.comms.get('flx', '/api/assets/industries', (status, response) => {
            return response
        }).then(response => {
            return response.industries
        })
    }

    /**
     * Makes API call to FLX to return curated list of Sources.
     * @returns {Promise<*>}
     * @private
     */
    async __getSources() {
        // eslint-disable-next-line no-unused-vars
        return await this.comms.get('flx', '/api/assets/sources', (status, response) => {
            return response
        }).then(response => {
            return response.sources
        })
    }
}