<template>
  <div v-if="!oAuthHtml">
    <v-container>
      <v-row>
        <v-col id="scanner-container">
          <div ref="scanner" class="scanner">
            <qrcode-stream @decode="searchResult" />
            <div id="qr-note">Place QR code inside this area</div>
            <div id="square-overlay"></div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="6" offset="3">
          <flx-input :quiet="true" :input="manualCodeInput"></flx-input>
          <v-btn
              color="mainMediumBlue"
              :disabled="manualCodeInput.value === ''"
              tile
              @click="searchResult(manualCodeInput.value)"
          >Submit</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else v-html="oAuthHtml" />
</template>

<script>
import flxInput from '../components/flxInput'
import User from "../services/User";

// @link: https://github.com/gruhn/vue-qrcode-reader
import { QrcodeStream } from "vue-qrcode-reader"

export default {
  name: "Scanner",
  components: {
    flxInput,
    QrcodeStream
  },

  data: () => ({
    manualCodeInput: {
      name: 'code',
      label: 'Enter Code Manually',
      placeholder: 'QR Code Number',
      type: 'text',
      value: '',
      rules: ['min:4']
    },
    oAuthHtml: null
  }),

  computed: {
  },

  created() {
      this.user = this.$store.getters.user === null ? new User() : this.$store.getters.user
  },

  beforeMount() {
    // this.initCamera()
  },

  mounted() {
  },

  beforeDestroy() {
  },

  watch: {},

  methods: {
    // async initCamera() {
    //   const camera = await navigator.mediaDevices.getUserMedia({ video: true })
    //   camera.getTracks().forEach(track => {
    //     track.stop();
    //   });
    // },

    async searchResult(code) {
      await this.user.startOauth(code).then(data => {
        this.oAuthHtml = data.data
        const bootstrap = document.createElement('link')
        bootstrap.href = "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css"
        bootstrap.rel = 'stylesheet'
        document.head.appendChild(bootstrap)
      }).then(() => {
        // Automate code submit
        this.$nextTick()
        document.getElementById('signIn').submit()
      })
    },
  }
};
</script>
<style scoped>

#scanner-container {
  position: relative;
  max-width: 100%;
  height: 60vh;
  margin-left: auto;
  margin-right: auto;
}

.scanner {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  /*outline: 2px solid black;*/
  width: 70%;
  min-width: 300px;
  max-width: 620px;
  height: 60vh;
}

video,
canvas {
  z-index: 0;
  position: fixed;
  right: 0;
  height: 60vh;
  transform: translateX(calc((100% - 100vw) / 2));
  min-width: 100%;
}

.drawingBuffer {
  z-index: 5;
  position: fixed;
  right: 0;
  height: 60vh;
  transform: translateX(calc((100% - 100vw) / 2));
  /*outline: 2px solid black;*/
  min-width: 100%;
}

/*#overlay {*/
/*  !*position: absolute;*!*/
/*  z-index: 25;*/
/*  !*width: 100%;*!*/
/*  height: 150px;*/
/*  opacity: 0.2;*/
/*  top: 0;*/
/*}*/

#qr-note {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0.15rem;
  color: white;
  padding: 2px 5px;
  top: 5rem;
  left: 0;
  right: 0;
  width: 14.2rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
}

#square-overlay {
  position: absolute;
  /*display: flex;*/
  outline: 2px solid white;
  top: 7rem;
  bottom: 5rem;
  left: 40px;
  right: 40px;
  margin-left: auto;
  margin-right: auto;
}
</style>
