<template>
  <div>
    <styled-background :image="currentBackgroundImage"></styled-background>
    <v-card v-if="level3Callback" tile class="mx-auto" width="90%" height="100%" v-html="level3Callback"></v-card>
    <v-card
        v-else-if="robinHtml"
        flat
        width="90%"
        class="mx-auto"
        style="background-color: transparent;"
    >
      <v-card-title class="d-flex justify-center" v-text="title"/>
      <v-card v-if="!loading" tile class="mx-auto" :width="robinWidth" height="70vh">
        <iframe id="robinIframe" ref="robinIframe" :srcdoc="robinHtml"></iframe>
      </v-card>
    </v-card>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import styledBackground from "../components/styledBackground";

export default {
  /* eslint-disable */
  name: "Robin",
  components: {
    styledBackground
  },
  props: {
    backgroundImage: {
      type: String,
      required: false,
      default: 'menuImages/new-robin-small.png'
    },

    title: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['user', 'storedImage', 'r3SessionToken', 'robinToken']),

    currentBackgroundImage: function () {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },

    botId() {
      if (this.user.level === 0) {
        return 'robinnew'
      }
      return 'robinsupport'
    },

    userId() {
      switch (this.user.level) {
        case 0:
          return `${this.user.deviceId}-guest`
        case 3:
          return this.user.deviceId
        default:
          return this.user.caseCode
      }
    },

    robinWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%"
        case "sm":
          return "90%"
        case "md":
          return "70%"
        case "lg":
        case "xl":
          return "60%%"
      }
      return "80%"
    }
  },

  data: () => ({
    robinHtml: null,
    level3Callback: null,
    loading: false
  }),

  watch: {
  },

  created() {
    this.loading = true
  },

  mounted() {
    window.addEventListener('message', this.messageListener)
    this.buildHtml()
    this.loading = false
  },

  beforeDestroy() {
    window.removeEventListener('message', this.messageListener)
  },

  methods: {
    messageListener(message) {
      // Double check the message is coming from the expected place to verify the source document is loaded.
      if (message.data.source === 'robinHtml' && message.data.func === 'robinLoaded') {
        this.buildRobin()
      }
    },

    async buildRobin() {
      let token = null
      if (this.user.level === 3 && this.robinToken === '') {

        this.user.registerLevel3RobinCall()
        const callback = await this.user.authorizeRobin()
        this.level3Callback = callback.data
        await this.$nextTick()
        document.getElementById('data_attributes_username').value = this.user.deviceId
        document.getElementById('data_attributes_password').value = this.user.caseCode
        document.getElementById('signIn').submit()
        return
      } else if (this.user.level === 3) {
        token = this.robinToken
      }
      const params = {
        host: "https://bpchat.r3c.com",
        botId: this.botId,
        userId: this.userId,
        enableReset: false,
        enableTranscriptDownload: false,
        extraStylesheet: "assets/modules/channel-web/r3.css",
      }
      if (this.user.level !== 0) {
        params.externalAuthToken = token ? `${token}` : `${this.r3SessionToken}`
      }

      let srcWindow = this.$refs.robinIframe.contentWindow
      if (!srcWindow.botpressWebChat) {
        console.log('error retrieving botPress bot')
        return
      }

      // srcWindow.initRobin(params)
      srcWindow.botpressWebChat.init(params)
      this.addRobinListeners(srcWindow)
    },

    addRobinListeners(srcWindow) {
      srcWindow.addEventListener('message', event => {
        if (event.data?.name === 'webchatLoaded') {
          srcWindow.botpressWebChat.mergeConfig({
            containerWidth: '100%',
            layoutWidth: '100%'
          })
          srcWindow.botpressWebChat.sendEvent({type: 'show'})
        }
        if (event.data?.name === 'webchatReady') {
          srcWindow.botpressWebChat.sendEvent({
            type: 'proactive-trigger',
            channel: 'web',
            payload: {text: 'discarded message'}
          })
        }
        if (event.data?.text === 'unauthorized') {
          console.log('unauthorized message', event.data, JSON.parse(JSON.stringify(event)))
          // srcWindow.botpressWebChat.sendEvent({type: "hide"});
          // srcWindow.botpressWebChat.configure({hideWidget: "true"});
        }
      });

      // srcWindow.addEventListener('message', function (event) {
      //   // changed the event filter here from webchatOpened to webchatReady
      //   if (event.data?.name === 'webchatReady') {
      //     srcWindow.botpressWebChat.sendEvent({
      //       type: 'proactive-trigger',
      //       channel: 'web',
      //       payload: {text: 'discarded message'}
      //     })
      //   }
      // });

      // srcWindow.addEventListener("message", function (message) {
      //   if (message.data.text === 'unauthorized') {
      //     console.log('unauthorized message', message.data, JSON.parse(JSON.stringify(message)))
      //     // srcWindow.botpressWebChat.sendEvent({type: "hide"});
      //     // srcWindow.botpressWebChat.configure({hideWidget: "true"});
      //   }
      // });
    },

    buildHtml() {
      this.robinHtml =
          `<html>
           <head>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0">
           </head>
           <body>
           <script>
              document.addEventListener("DOMContentLoaded", pullSource())

              function pullSource() {
                  const script = document.createElement('script')
                  script.src = "https://bpchat.r3c.com/assets/modules/channel-web/custom_inject.js"
                  document.body.appendChild(script)
                  new Promise((resolve, reject) => {
                      script.onload = resolve
                      script.onerror = reject
                  }).then(() => {
                      // Let vue app know that the chat bot resources are ready to be initialized
                      window.parent.postMessage({
                          'source': 'robinHtml',
                          'func': 'robinLoaded',
                      }, "*")
                  })
              }
           <\/script>
           </body>
           </html>`

    }
  }
}
</script>

<style scoped>
#robinIframe {
  height: 100%;
  width: 100%;
  border: 0;
}
</style>