var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
          backgroundImage: ("url(" + _vm.imageSource + ")"),
          position: 'fixed',
          top: '-100px',
          left: '-20px',
          width: '700px',
          height: '600px',
          opacity: _vm.opacity,
          backgroundRepeat: 'no-repeat'
        }),attrs:{"id":"background-image"}})}
var staticRenderFns = []

export { render, staticRenderFns }