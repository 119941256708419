<template>
<!--  <v-parallax :src="imageSource"/>-->
  <div id="background-image"
       :style="{
          backgroundImage: `url(${imageSource})`,
          position: 'fixed',
          top: '-100px',
          left: '-20px',
          width: '700px',
          height: '600px',
          opacity: opacity,
          backgroundRepeat: 'no-repeat'
        }"
  ></div>
</template>

<script>
export default {
  name: "styledBackground",
  props: {
    image: {
      type: String,
      required: false,
      default: 'menuImages/head-desk.png'
    },

    opacity: {
      type: String,
      required: false,
      default: '58%'
    }
  },
  computed: {
    imageSource: function () {
      if (this.image.includes('https://')) {
        // console.log('this image', this.image)
        return require(this.image)
      }
      return require(`../assets/${this.image}`)
    },
  }
}
</script>

<style scoped>
</style>