<template>
  <v-list v-if="trainingData && trainingData.length > 0" dense>
<!--    <v-row>-->
<!--      <v-col class="d-flex justify-center">-->
<!--        <h3 class="text-capitalize customer&#45;&#45;text">{{ type || null }}</h3>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-divider></v-divider>-->
    <v-list-item
      v-for="training in trainingData"
      :key="training.file_id"
      @click="$emit('training-tapped', type, training)"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ getName(training) }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="h-100">
        <v-row class="h-100">
          <v-col v-if="isCertifiedTraining">
            <v-icon
              v-if="training.complete && ['training'].includes(entityType)"
              color="green"
              >check
            </v-icon>
            <v-icon
              v-else-if="!training.complete && ['training'].includes(entityType)"
              color="red"
              >clear
            </v-icon>
          </v-col>
          <v-col>
            <v-icon right v-text="getDocumentIconName(training)"></v-icon>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
<!--    <v-divider></v-divider>-->
  </v-list>
</template>

<script>
export default {
  name: "TrainingListItem",
  props: {
    trainingGroup: {
      type: Object,
      required: true
    },

    isCertifiedTraining: {
      type: Boolean,
      required: false,
      default: false
    },

    entityType: {
      type: String,
      required: false,
      default: 'product'
    }
  },
  data: () => ({
    type: null,
    trainingData: null
  }),

  beforeMount() {
    Object.entries(this.trainingGroup).forEach(([key, value]) => {
      this.type = key;
      this.trainingData = value;
    });
  },

  methods: {
    getDocumentIconName(document) {
      switch (this.type) {
        case 'videos':
          return "ondemand_video"
        case 'surveys':
          return "mdi-help-circle-outline"
        case 'questions':
          return "mdi-school"
      }
      switch (document.format) {
        case "web":
          return "web_asset";
        case "pdf":
          return "picture_as_pdf";
        case "jpg":
          return "photo";
        case "png":
          return "photo";
        default:
          return "picture_as_pdf";
      }
    },

    getName(training) {
      switch (this.type) {
        case "videos":
        case "documents":
          return training.file_name;
        case "questions":
          return training.test_name;
        case "surveys":
          return training.survey_name;
      }
    }
  }
};
</script>

<style scoped></style>
