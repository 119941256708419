<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" overlay-opacity="0.80">
    <v-card tile width="90vw" min-height="80vh" class="mx-auto d-flex flex-column">
      <v-card-text>
        <v-container class="my-4">

          <!-- Header -->
          <v-row>
            <v-col>
              <v-card flat width="100%">
                <v-card-title class="d-flex justify-center">{{ title }}</v-card-title>
                <v-card-subtitle class="d-flex justify-center">
                  <v-container>
                    <v-row justify="center">{{ date }}</v-row>
                    <v-row justify="center">{{ resolveTime() }}</v-row>
                  </v-container>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>

          <!-- Details -->
          <v-row>
            <!-- Case Details -->
            <v-col cols="12" md="4" offset-md="1">
              <template v-for="(item, index) in caseDetails">
                <v-row v-if="item.text" :key="index">
                  <v-card width="100%" class="mx-auto" flat style="background-color: transparent">
                    <v-card-title class="justify-md-end justify-center" v-text="item.name"/>
                    <v-card-subtitle class="text-md-right text-center" style="text-transform: capitalize;" v-html="item.text"/>
                  </v-card>
                </v-row>
              </template>
            </v-col>
            <!-- Specialist -->
            <v-col cols="12" md="6">
              <template v-if="specialistDetails.length > 0">
                <template v-for="specialist in specialistDetails">
                  <!-- Photo and Name -->
                  <v-card flat class="mx-auto" style="background-color: transparent;" width="90%" :key="specialist.id">
                    <v-img
                        class="ml-0"
                        height="225"
                        contain
                        alt="Specialist Photo"
                        :src="getPhoto(specialist.photo)"
                    ></v-img>
                    <v-card-title class="d-flex justify-center">{{ specialist.name }}</v-card-title>
                    <v-divider />
                  </v-card>
                  <!-- Specialties/Focus Areas/Languages -->
                  <v-row no-gutters :key="specialist.id">

                    <template v-for="(attribute, key) in specialist">
                      <template v-if="['languages', 'focusAreas', 'specialties'].includes(key) && attribute.length > 0">
                        <v-col :key="key" cols="12" sm="6" class="d-inline-flex">
                          <v-card
                              flat
                              style="background-color: transparent;"
                              class="mx-auto"
                              width="80%"
                              :key="key"
                          >
                            <v-card-title
                                class=""
                                style="text-transform: capitalize;"
                                v-text="key"
                            />
                            <v-card-subtitle>
                              <v-container>
                                <v-row
                                    v-for="item in attribute"
                                    :key="item"
                                    class="text-wrap text-break"
                                    v-text="item"
                                />
                              </v-container>
                            </v-card-subtitle>
                          </v-card>
                        </v-col>
                      </template>
                    </template>

                  </v-row>
                </template>
              </template>
              <template v-else>
                <v-skeleton-loader class="mx-6" :boilerplate="!loadingSpecialist" type="card-avatar, article"/>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-spacer/>
      <v-card-actions>
        <v-row justify="center" class="mb-4">
          <v-btn color="mainBlue" style="color: white;" @click="$emit('shift-details', false)">Close</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "incidentDetails",
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },

    incidentType: {
      type: String,
      required: false,
      default: ''
    },

    shift: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },

    caseAttributes: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },

  computed: {
    ...mapGetters(['user']),

    title() {
      return this.caseAttributes.client_attributes?.name || ''
    },

    date() {
      return this.$moment(this.shift?.scheduled_date).format('L') || ''
    },
  },

  data: () => ({
    dialog: false,
    caseDetails: [],
    specialistDetails: [],
    loadingSpecialist: false,
    locationName: '',
    locationDescription: '',
    locationAddress: '',
    siteContact: '',
  }),

  watch: {
    show(val) {
      this.dialog = val;
      if (!val) {
        this.clearShift()
      }
    },

    shift(val) {
      // console.log('shift watch active')
      if (Object.keys(val).length > 0) {
        this.populateShift()
      }
    }
  },

  mounted() {
  },

  methods: {
    populateShift() {
      this.resolveLocationName()
      this.resolveLocationDescription()
      this.resolveLocationAddress()
      this.resolveSiteContact()
      this.buildDetails()
    },

    clearShift() {
      this.locationName = ''
      this.locationDescription = ''
      this.locationAddress = ''
      this.siteContact = ''
      this.caseDetails = []
      this.specialistDetails = []
    },

    resolveTime() {
      if (this.shift.time_zone_attributes) {
        const startTime = this.$moment(this.shift.start_time).tz(this.shift.time_zone_attributes.canonical_identifier)
        const endTime = this.$moment(this.shift.end_time).tz(this.shift.time_zone_attributes.canonical_identifier)
        const tz = this.shift.time_zone_attributes?.name || ''
        const start = startTime?.format('LT') || ''
        const end = endTime?.format('LT') || ''
        return `${start} - ${end} ${tz}`
      }
      return ''
    },

    resolveLocationName() {
      this.locationName = this.shift.location_name || ''
    },

    resolveLocationDescription() {
      this.locationDescription = this.shift.location_description || ''
    },

    resolveLocationAddress() {
      const stringBreak = this.shift.site_address?.length > 0 ? `<br/>` : ''
      const locationString = `${this.shift.site_address}${stringBreak}${this.shift.site_city}, ` +
          `${this.shift.site_state_attributes.description} ${this.shift.location_zip}`
      this.locationAddress = locationString || ''
    },

    resolveSiteContact() {
      const contactCollection = this.shift?.shift_contacts_attributes || []
      let contactString = ''
      for (let i = 0; i < contactCollection.length; i++) {
        contactString += `${contactCollection[i].contact_name}`
        if (contactCollection[i].contact_title) {
          contactString += `, ${contactCollection[i].contact_title}`
        }
        if (contactCollection[i].phone_1) {
          contactString += ` <a href="tel:+1${contactCollection[i].phone_1}">${contactCollection[i].phone_1}</a>`
        }
        if (contactCollection[i].phone_1_ext) {
          contactString += ` ext: ${contactCollection[i].phone_1_ext}`
        }
        if (contactCollection[i].phone_2) {
          contactString += ` | <a href="tel:+1${contactCollection[i].phone_2}">${contactCollection[i].phone_2}</a>`
        }
        if (contactCollection[i].phone_2_ext) {
          contactString += ` ext: ${contactCollection[i].phone_2_ext}`
        }
        if (contactCollection[i].phone_3) {
          contactString += ` | <a href="tel:+1${contactCollection[i].phone_3}">${contactCollection[i].phone_3}</a>`
        }
        if (contactCollection[i].phone_3_ext) {
          contactString += ` ext: ${contactCollection[i].phone_3_ext}`
        }
        if (i + 1 !== contactCollection.length) {
          contactString += '<br/>'
        }
      }
      this.siteContact = contactString || ''
    },

    getPhoto(photo) {
      return [null, undefined, ''].includes(photo.url) ? require('../assets/profile-placeholder.png') : photo.url
    },

    async buildDetails() {
      this.caseDetails = [
        {
          name: 'Incident',
          text: this.incidentType
        },
        {
          name: 'Location Name',
          text: this.locationName
        },
        {
          name: 'Location Description',
          text: this.locationDescription
        },
        {
          name: 'Location Address',
          text: this.locationAddress
        },
        {
          name: 'Site Contact',
          text: this.siteContact
        }
      ]
      this.loadingSpecialist = true
      await this.buildSpecialistProfiles(await this.user.getSpecialistData(this.shift.id))
      this.loadingSpecialist = false
    },

    async buildSpecialistProfiles(specialists = []) {
      this.specialistDetails = []
      for (const index in specialists) {
        const specialist = specialists[index]
        // console.log('specialist:', specialist)
        const obj = {}
        const attrs = specialist.attributes
        if (attrs) {
          obj.photo = await this.user.getProps(`api/contacts/get_photo/${specialist.id}`)
          obj.name = `${attrs.prefix} ${attrs.first_name} ${attrs.last_name} ${attrs.suffix}`
          obj.languages = []
          for (const item in attrs.languages_descriptions) {
            obj.languages.push(attrs.languages_descriptions[item])
          }
          obj.focusAreas = []
          for (const item in attrs.documents_descriptions) {
            obj.focusAreas.push(attrs.documents_descriptions[item])
          }
          obj.specialties = []
          for (const item in attrs.specialties_descriptions) {
            obj.specialties.push(attrs.specialties_descriptions[item])
          }
          for (const item in attrs.specialty_network_descriptions) {
            obj.specialties.push(attrs.specialty_network_descriptions[item])
          }
          this.specialistDetails.push(obj)
        }
      }
      if (this.specialistDetails.length === 0) {
        this.specialistDetails.push({photo: ''})
      }
      // console.log('specialist details', JSON.parse(JSON.stringify(this.specialistDetails)))
    }

  }
}
</script>

<style scoped>

</style>