<template>
  <div>Hi</div>
</template>
<script>
export default {
  name: "Callback",
  mounted() {
    const robin = window.$cookies.get('robinLogin') || false
    if (robin) {
      window.$cookies.remove('robinLogin')
      this.$store.dispatch('robinCallbackSuccessful', window.location.hash.substr(1))
    } else {
      this.$store.dispatch('callbackSuccessful', window.location.hash.substr(1))
    }
  }
}
</script>
<style scoped>
</style>