<template>
  <v-container>
    <template v-if="loading">
      <v-row v-for="r in 2" :key="r">
        <v-col v-for="c in 4" :key="c">
          <v-skeleton-loader class="ma-4" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col
            v-for="product in whatToExpect"
            :key="product.product_id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
        >
          <material-card
              :image-source="product.featured_image"
              :title="product.name"
              :subtitle="product.description"
              @item-clicked="navigateTo(product)"
          ></material-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import MaterialCard from "../materialCard";

export default {
  name: "whatToExpect",
  components: {
    MaterialCard
  },
  props: {
    whatToExpect: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    navigateTo(product) {
      this.$store.dispatch('setLastIncidentTab', 1)
      this.$router.push({name: 'Product Page', params: {trainingItem: product}})
    }
  }
}
</script>

<style scoped>

</style>