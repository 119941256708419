<template>
  <div>
    <styled-background :image="currentBackgroundImage"></styled-background>
    <v-card width="500" flat class="mx-auto" style="background: transparent;">
      <v-card-title class="d-flex justify-center">{{ title }}</v-card-title>
      <v-card-subtitle class="text-center mt-3">
        {{ subtitle }}
      </v-card-subtitle>
    </v-card>
    <v-container>
      <template v-if="loading">
        <v-row v-for="r in 2" :key="r">
          <v-col v-for="c in 4" :key="c">
            <v-skeleton-loader class="ma-4" type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col
              v-for="product in searchItem"
              :key="product.product_id"
              cols="6"
              sm="4"
              lg="3"
          >
            <material-card
                :image-source="product.featured_image"
                :title="product.name"
                :subtitle="product.description"
                @item-clicked="navigateTo(product)"
            ></material-card>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import styledBackground from "@/components/styledBackground";
import materialCard from "@/components/materialCard";
import ProductPage from "@/views/ProductPage";

export default {
  name: "SearchResults",
  components: {
    styledBackground,
    materialCard
  },
  props: {
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    },

    title: {
      type: String,
      required: false,
      default: 'Results'
    },

    subtitle: {
      type: String,
      required: false,
      default: ''
    },

    search: {
      type: Array,
      required: false,
      default: null
    },
  },
  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters(["storedSearchResults", "storedImage"]),

    currentBackgroundImage: function () {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },

    searchItem: function () {
      return this.search === null ? this.storedSearchResults : this.search
    },

    setMenuImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "50";
        case "md":
          return "75";
        case "lg":
          return "100";
        case "xl":
          return "100";
      }
      return 5;
    },
  },

  watch: {
  },

  created() {
  },

  mounted() {
    if (this.search) {
      this.$store.dispatch('setStoredSearchResults', this.search)
    }
  },

  updated() {
  },

  methods: {

    navigateTo (product) {
      // console.log('navigating to ', product)
      this.$router.push({
        name: "Product Page",
        component: ProductPage,
        params: {
          backgroundImage: this.backgroundImage,
          trainingItem: product
        }
      });
    }
  }
};
</script>

<style scoped>
</style>
