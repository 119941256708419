<template>
  <v-app>
    <!-- Header (Home) -->
    <v-app-bar
        v-if="['Login', 'Menu'].includes($route.name)"
        app
        style="background: transparent;"
        flat
        prominent
    >
      <v-btn
          v-if="!['Login'].includes($route.name)"
          icon
      >
      </v-btn>
      <v-spacer></v-spacer>
      <v-img
          alt="R3 Continuum"
          contain
          class="my-auto"
          src="@/assets/R3C_Logo_Transparent.png"
          transition="scale-transition"
          max-width="300"
          @click="goHome"
      />
      <v-spacer></v-spacer>
      <v-btn
          v-if="!['Login'].includes($route.name)"
          @click="logout"
          icon
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- if Level 3 Robin -->
    <v-app-bar
        v-else-if="user && user.level === 3 && $route.name === 'Robin'"
        app
        style="background: transparent;"
        absolute
        flat
    >
      <v-btn icon @click="closeTab">
        <v-icon color="black">mdi-keyboard-backspace</v-icon>
      </v-btn>
      <v-spacer/>
      <v-img
          alt="R3 Continuum"
          contain
          class="my-auto"
          src="@/assets/R3C_Logo_Transparent.png"
          transition="scale-transition"
          max-width="300"
          @click="goHome"
      />
      <v-spacer/>
      <v-btn icon >
      </v-btn>
    </v-app-bar>

    <!-- Header (not home) -->
    <v-app-bar
        v-else
        app
        style="background: transparent;"
        absolute
        flat
    >
      <v-btn icon @click="$router.back()">
        <v-icon color="black">mdi-keyboard-backspace</v-icon>
      </v-btn>
      <v-spacer/>
      <v-img
          alt="R3 Continuum"
          contain
          class="my-auto"
          src="@/assets/R3C_Logo_Transparent.png"
          transition="scale-transition"
          max-width="300"
          @click="goHome"
      />
      <v-spacer/>
      <v-btn
          v-if="!['Scanner', 'Self Registration'].includes($route.name)"
          @click="$router.push({ name: 'Menu' })"
          icon
      ><v-icon color="black">mdi-home</v-icon>
      </v-btn>
      <v-btn text icon v-else></v-btn>
    </v-app-bar>

    <v-main
        :style="{background: $vuetify.theme.themes[theme].background}"
        style="padding-bottom: 30px;"
    >
      <router-view background-color="primary"></router-view>
    </v-main>
    <v-footer v-if="showFooter" app style="background-color: transparent" height="100">
      <v-card tile width="300" color="mainBlue" class="mx-auto">
        <v-card-title class="d-flex justify-center white--text" v-text="version" />
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'App',

  components: {},

  computed: {
    ...mapGetters(['user']),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  data: () => ({
    showFooter: false,
    version: `Version: ${process.env.VUE_APP_VERSION}`
  }),

  methods: {
    goHome() {
      if (!['Menu', 'Login', 'Scanner', 'Self Registration'].includes(this.$route.name) &&
          this.$store.getters.user !== null) {
        this.$router.push({name: 'Menu'})
      } else {
        this.showFooterMethod()
      }
    },

    logout() {
      this.user.loggedIn = false
      this.$router.push({name: 'Login'})
    },

    closeTab() {
      window.close()
    },

    showFooterMethod() {
      this.showFooter = true
      setTimeout(() => this.showFooter = false, 1603)
    }
  }
};
</script>
