<template>
  <div>
    <styled-background :image="currentBackgroundImage"></styled-background>
    <v-card width="500" flat class="mx-auto" style="background: transparent;">
      <v-card-title class="d-flex justify-center">Support Materials
      </v-card-title>
      <v-card-subtitle class="text-center mt-3">
        Learn about workplace disruption and solutions available to help you support and ready
        your organization.
      </v-card-subtitle>
    </v-card>
    <v-container>
      <template v-if="loading">
        <v-row>
          <template v-for="c in 8">
            <v-col
                cols="6"
                sm="4"
                lg="3"
                :key="c"
            >
              <v-skeleton-loader
                  class="ma-4"
                  type="card"
              />
            </v-col>
          </template>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col
              v-for="product in localMaterials"
              :key="product.product_id"
              cols="6"
              sm="4"
              lg="3"
          >
            <material-card
                :image-source="product.featured_image"
                :title="product.name"
                :subtitle="product.description"
                @item-clicked="navigateTo(product)"
            ></material-card>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import styledBackground from "@/components/styledBackground";
import materialCard from "@/components/materialCard";
import ProductPage from "@/views/ProductPage";

export default {
  name: "Materials",
  components: {
    styledBackground,
    materialCard
  },
  props: {
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    localMaterials: [],
    loading: true
  }),

  computed: {
    ...mapGetters(['user', 'storedImage']),

    currentBackgroundImage: function () {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },

    setMenuImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "50";
        case "md":
          return "75";
        case "lg":
          return "100";
        case "xl":
          return "100";
      }
      return 5;
    },
  },

  watch: {
  },

  created() {
    this.getMaterials()
  },

  mounted() {
  },

  updated() {
  },

  methods: {
    async getMaterials() {
      this.localMaterials = await this.user.materials
      this.loading = false
    },

    navigateTo (product) {
      // console.log('navigating to ', product)
      this.$router.push({
        name: "Product Page",
        component: ProductPage,
        params: {
          trainingItem: product,
          backgroundImage: this.currentBackgroundImage
        }
      });
    }
  }
};
</script>

<style scoped>
</style>
