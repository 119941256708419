<template>
  <div>
    <styled-background :image="currentBackgroundImage" opacity="35%"/>
    <v-card tile width="80%" class="mx-auto">
      <v-card-title class="d-flex justify-center">Support Your Event</v-card-title>
      <v-card-subtitle class="d-flex justify-center">
        Stay connected in real time to scheduled response support for active and past events.
      </v-card-subtitle>
      <!-- Search Filters -->
      <validation-observer v-slot="{ invalid }">
        <v-form ref="incidentListFilterForm" @submit.prevent="filterList">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="6" v-for="(item, index) in dateFilters" :key="index">
                <flx-input :input="item"/>
              </v-col>
              <v-col cols="12">
                <flx-input :input="clientFilter"/>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-btn
                  type="submit"
                  color="mainBlue"
                  style="color: white;"
                  :loading="loading"
                  :disabled="invalid"
              >Search</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>

      <!-- Detailed List -->
      <v-card flat style="background-color: transparent;" width="85%" class="mx-auto">
        <v-divider v-if="detailedList.length > 0" />
        <v-list three-line>
          <template v-for="(listItem, index) in detailedList">
            <v-list-item :key="listItem.id" class="fill-height" ripple @click="navigateTo(listItem)">
              <v-list-item-content>
                <v-list-item-title v-text="resolveTitle(listItem)"/>
                <v-list-item-subtitle style="text-transform: capitalize;" v-text="resolveDescription(listItem)"/>
                <v-list-item-subtitle v-text="resolveLocation(listItem)"/>
              </v-list-item-content>
              <v-list-item-icon class="h-100">
                <v-icon class="my-auto">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider :key="index"/>
          </template>
        </v-list>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import StyledBackground from "../components/styledBackground";
import FlxInput from "../components/flxInput";

export default {
  name: "IncidentList",
  components: {
    FlxInput,
    StyledBackground,
    ValidationObserver
  },
  props: {
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['user', 'storedImage', 'serviceLogisticsData', 'serviceLogisticsClients', 'lastIncidentList']),
    currentBackgroundImage() {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },

    fullList() {
      return this.serviceLogisticsData
    },
  },

  data: () => ({
    dateFilters: [],
    dateResults: {},
    startDateFilter: null,
    endDateFilter: null,
    clientFilter: {},
    showDatepicker: false,
    detailedList: [],
    loading: false
  }),

  watch: {},

  mounted() {
    this.$store.dispatch('setLastIncidentTab')
    this.detailedList = this.lastIncidentList ? this.lastIncidentList : []
    this.dateFilters = [
      {
        name: 'startDate',
        label: 'Start Date',
        type: 'date',
        value: ''
      },
      {
        name: 'endDate',
        label: 'End Date',
        type: 'date',
        value: ''
      },
    ]
    this.clientFilter = {
      name: 'client',
      label: 'Client',
      type: 'combo',
      value: '',
      selectOptions: this.serviceLogisticsClients,
      rules: ['required']
    }
  },

  updated() {
  },

  methods: {
    filterList() {
      // get date filters
      for (const filter in this.dateFilters) {
        this.dateResults[this.dateFilters[filter].name] = this.dateFilters[filter].value
      }

      let result = []
      if (this.fullList) {
        const filter = {
          client: this.clientFilter.value || null
        }
        result = this.fullList.filter(item => {
          let keep = false
          if (filter.client && filter.client === item.attributes.name) {
            keep = true
          }
          return keep
        })
      }
      this.buildDetailedList(result)
    },

    async buildDetailedList(list) {
      this.loading = true
      let remoteData = []
      let result = []
      // Get Details
      for (const item in list) {
        remoteData.push(await this.user.getServiceLogisticsDetails(list[item].id))
      }
      // Filter by date
      const startDate = this.$moment(this.dateResults?.startDate)
      const endDate = this.$moment(this.dateResults?.endDate)
      for (const index in remoteData) {
        /*
         * Some detail calls return an empty array, while some return an array of several objects. The next
         * block filters that down to a single array of objects while also implementing the date filter if
         * either of those fields are present.
         */
        if (remoteData[index]) {
          result.push(...remoteData[index].filter(caseData => {
            if (startDate.isValid() || endDate.isValid()) { // implement date filter
              const testDate = this.$moment(caseData.attributes?.incident_date)
              if (testDate.isValid()) {
                // console.log('test date', testDate.inspect())
                if (startDate.isValid() && endDate.isValid()) {
                  // console.log('start date:', startDate.inspect())
                  // console.log('end date', endDate.inspect())
                  return (testDate.isAfter(startDate) && testDate.isBefore(endDate))
                } else if (startDate.isValid()) {
                  // console.log('start date:', startDate.inspect())
                  return testDate.isAfter(startDate)
                } else if (endDate.isValid()) {
                  // console.log('end date', endDate.inspect())
                  return testDate.isBefore(endDate)
                }
              } else {
                return false // testDate not valid
              }
            } else {
              return true // date filter not implemented
            }
          }))
        }
      }
      // console.log('detailed list', JSON.parse(JSON.stringify(result)))
      this.detailedList = result
      await this.$store.dispatch('setLastIncidentList', this.detailedList)
      this.loading = false
      this.clearDateFilters()
    },

    resolveTitle(listItem) {
      let title = 'Title Unavailable'
      if (listItem.attributes?.case_attributes?.client_attributes) {
        title = listItem.attributes.case_attributes.client_attributes.name
      }
      return title
    },

    resolveDescription(listItem) {
      let description = 'Description Unavailable'
      const date = this.$moment(listItem.attributes.incident_date)
      const dateString = date.isValid() ? ` on ${date.format("L")}` : ''
      if (listItem.attributes?.incident_type_attributes) {
        description = listItem.attributes.incident_type_attributes.description
      }
      return `${description}${dateString}`
    },

    resolveLocation(listItem) {
      let location = 'Location Unavailable'
      let state = ''
      let city = ''
      const listItemAttributes = listItem.attributes?.shifts_attributes
      // console.log('attributes', listItemAttributes)
      if (listItemAttributes[0] && listItemAttributes[0].site_state_attributes) {
        state = listItemAttributes[0].site_state_attributes.description
        city = listItemAttributes[0].site_city
        // console.log('state | city', state, city)
      }
      if (state && city) {
        location = `${city}, ${state}`
      }
      return location
    },

    navigateTo(listItem) {
      this.$store.dispatch('setLastIncident', listItem)
      const params = {
        backgroundImage: this.currentBackgroundImage,
        incident: listItem
      }
      this.$router.push({
        name: 'Incident',
        params: params
      })
    },

    clearDateFilters() {
      this.dateResults = {}
      this.dateFilters.forEach(obj => {
        obj.value = ''
      })
    }
  }
};
</script>

<style scoped>
.menu-font {
  font-family: "Nobile", sans-serif;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 3px 3px 1px -2px rgba(0, 0, 0, 0.14),
  0px 2px 2px 0px rgba(100, 152, 66, 0.3), 0px 1px 5px 0px rgba(100, 152, 66, 0.25);
}
</style>
