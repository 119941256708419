import axios from "axios";
import store from "../vuex/store";

export default class Comms {

    redirect_host = `${window.location.origin}/callback`;

    constructor() {
        // FLX Client
        const FLXApiClient = axios.create({
            baseURL: process.env.VUE_APP_FLX_BASE_URL,
            headers: {
                Accept: "*/*",
                "Content-Type": "application/json"
            }
        });

        FLXApiClient.interceptors.request.use(config => {
            const token = store.state.FLXSessionToken;
            config.headers.Authorization = token;

            return config;
        });

        FLXApiClient.interceptors.response.use(response => {
            store.state.FLXSessionToken = response.config.headers.Authorization;
            return response;
        });

        this.FLXApiClient = FLXApiClient

        // R3 Secured Client
        const R3Client = axios.create({
            baseURL: `${process.env.VUE_APP_R3_BASE_URL}/api`,
        });

        R3Client.interceptors.request.use(config => {
            const token = store.state.R3SessionToken;
            config.headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/vnd.api+json'
            }
            return config;
        }, error => {
            console.log('R3 request error', error)
        });

        R3Client.interceptors.response.use(response => {
            // store.state.R3SessionToken = response.config.headers.Authorization;
            return response;
        }, error => {
            console.log('R3 response error', error)
        });

        this.R3Client = R3Client
    }

    async get(type, path, callback) {
        let client
        switch (type) {
            case 'flx':
                client = this.FLXApiClient
                break;
            case 'r3':
                client = this.R3Client
                break;
            default:
                return 'Error 400: bad "type"'
        }
        const data = '' // Needed because axios removes header Content-Type
        const pathFix = await path
        return client.get(pathFix, {data: data}).then(
            response => callback(response.status, response.data)
        );
    }

    async post(type, path, payload, callback) {
        let client
        switch (type) {
            case 'flx':
                client = this.FLXApiClient
                break;
            case 'r3':
                client = this.R3Client
                break;
            default:
                return 'Error 400: bad "type"'
        }
        const pathFix = await path
        return client.request({
            method: 'POST',
            url: pathFix,
            responseType: 'json',
            data: payload
        }).then(response => callback(response.status, response.data));
    }

    /****** R3 Auth *****/
    /**
     * Gets the HTML for displaying Oauth from providing recipient code
     * sets the Oauth HTML src for displaying R3 iFrame data
     *
     * @param {String}code the code the user is given to login with or passed in from the mobile app
     * @returns {Promise<AxiosResponse<any>>} HTML for displaying iFrame for R3 Oauth
     */
    startOauth(code) {
        window.$cookies.set('caseCode', code, 60)

        const params = {
            response_type: 'code',
            redirect_uri: this.redirect_host,
            client_assertion_type:
                'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
            client_assertion: process.env.VUE_APP_CLIENT_ASSERTION
        }

        // Provider / Consultants use an email address.  Employees use a code.
        if (code.includes('@')) {
            // Provider / Consultant
            params['username'] = code
        } else {
            // Employee / Guest
            params['username'] = 'service_recipient'
            params['password'] = code
        }

        return axios.get(`${process.env.VUE_APP_R3_BASE_URL}/api/authorizations/new`, {
            headers: {'Content-Type': 'application/vnd.api+json'},
            params: params
        })
    }

    /**
     * Gets the tokens using a returned hash code
     *
     * @param {String}code is the hash returned from iFrame initial call
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateOauthWithHashAndGetTokens(code) {
        return axios.post(
            `${process.env.VUE_APP_R3_BASE_URL}/api/tokens`,
            {
                data: {
                    type: 'tokens',
                    attributes: {
                        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                        assertion: code,
                        redirect_uri: this.redirect_host
                    }
                }
            },
            {
                headers: {'Content-Type': 'application/vnd.api+json'},
                params: {
                    redirect_uri: this.redirect_host,
                    client_assertion_type:
                        'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
                    client_assertion: process.env.VUE_APP_CLIENT_ASSERTION,
                    // state: store.state
                }
            }
        )
    }

    authorizeRobin(deviceId, code) {
        const params = {
            username: deviceId,
            password: code,
            response_type: 'code',
            redirect_uri: this.redirect_host,
            client_assertion_type:
                'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
            client_assertion: process.env.VUE_APP_ROBIN_ASSERTION,
        }

        return axios.get(`${process.env.VUE_APP_ROBIN_URL}/api/authorizations/new`, {
            headers: {'Content-Type': 'application/vnd.api+json'},
            params: params
        })
    }

    /**
     * Gets the tokens using a returned hash code
     *
     * @param {String}code is the hash returned from iFrame initial call
     * @returns {Promise<AxiosResponse<any>>}
     */
    handleRobinCallback(code) {
        return axios.post(
            `${process.env.VUE_APP_ROBIN_URL}/api/tokens`,
            {
                data: {
                    type: 'tokens',
                    attributes: {
                        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
                        assertion: code,
                        redirect_uri: this.redirect_host
                    }
                }
            },
            {
                headers: {'Content-Type': 'application/vnd.api+json'},
                params: {
                    redirect_uri: this.redirect_host,
                    client_assertion_type:
                        'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
                    client_assertion: process.env.VUE_APP_ROBIN_ASSERTION,
                    // state: store.state
                }
            }
        )
    }

    authorizeProspect(payload) {
        return axios.post(
            `${process.env.VUE_APP_R3_BASE_URL}/api/v1/prospects`,
            {
                data: {
                    type: 'prospects',
                    attributes: payload
                }
            },
            {
                headers: {'Content-Type': 'application/vnd.api+json'},
                params: {
                    client_assertion_type:
                        'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
                    client_assertion: process.env.VUE_APP_CLIENT_ASSERTION
                }
            }
        )
    }

    registerLevel3RobinCall(deviceId, code) {
        return axios.post(
            `${process.env.VUE_APP_ROBIN_URL}/api/v1/chatbot_users`,
            {
                data: {
                    type: "service_users",
                    attributes: {
                        device_id: deviceId,
                        service_recipient_code: code
                    }
                }
            },
            {
                headers: {'Content-Type': 'application/vnd.api+json'},
                params: {
                    client_assertion_type:
                        'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
                    client_assertion: process.env.VUE_APP_ROBIN_ASSERTION
                }
            }
        )
    }
}
