<template>
  <v-card
      height="390"
      class="d-flex flex-column"
      style="cursor: pointer;"
      @click="$emit('item-clicked')"
      hover
      tile
  >
    <v-img :src="imageSource" :height="imgHeight" :min-height="imgHeight" :max-height="imgHeight" />
    <v-card-title style="font-size: 1.1em; line-height: 1.4" class="justify-center text-center text-break mb-2" v-text="title" />
    <v-card-subtitle class="text-center" v-text="subtitle" />
    <v-spacer/>
    <v-card-actions class=" blue--text d-flex justify-center">
      View
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "materialCard",
  props: {
    imageSource: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: 'Title'
    },
    subtitle: {
      type: String,
      required: false,
      default: 'Subtitle'
    }
  },
  data: () => ({
    imgHeight: 200
  })

}
</script>

<style scoped>

</style>