<template>
  <v-container>
    <div id="video-page">
      <v-row align="center" justify="center">
        <v-col>
          <v-row align="center" justify="center">
            <v-col>
              <video
                autoplay
                ref="videoPlayer"
                class="video-player"
                controls
                controlsList="nodownload"
              >
                <source :src="src" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-content-center">
              <v-btn
                block
                tile
                color="#eee"
                class="mx-auto"
                @click="closeVideo()"
                >Done</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div class="text-center">
      <v-snackbar v-model="snackbar" :timeout="timeout">
        Training complete!

        <template v-slot:action="{ attrs }">
          <v-btn
            color="secondary"
            text
            small
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
// import analyticService from "@/services/AnalyticService";

export default {
  name: "videopage",
  props: ["training", "parentId", "isCertified"],
  components: {},

  data: () => ({
    windowWidth: null,
    duration: null,
    src: null,
    needsMarkedWatched: true,
    snackbar: false,
    timeout: 3000
  }),

  beforeDestroy() {
    this.player.pause();
    this.$refs.videoPlayer.removeEventListener("error", this.errorListener);
    this.$refs.videoPlayer.removeEventListener(
      "loadedmetadata",
      this.loadedMetadataListener
    );
    this.$refs.videoPlayer.removeEventListener(
      "readyState",
      this.readyStateListener
    );
    this.$refs.videoPlayer.removeEventListener(
      "timeupdate",
      this.timeUpdateListener
    );
  },

  mounted() {
    this.src = this.training.file_url;
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    });
    this.$refs.videoPlayer.addEventListener("error", this.errorListener);
    this.$refs.videoPlayer.addEventListener(
      "loadedmetadata",
      this.loadedMetadataListener
    );
    this.$refs.videoPlayer.addEventListener(
      "readyState",
      this.readyStateListener
    );
    this.$refs.videoPlayer.addEventListener(
      "timeupdate",
      this.timeUpdateListener
    );
  },

  computed: {
    ...mapGetters(["user"]),
    player() {
      return this.$refs.videoPlayer;
    },
    width() {
      return this.windowWidth - 50;
    }
  },

  methods: {
    closeVideo() {
      this.player.pause();
      this.$emit("done");
    },

    markedVideoWatched() {
      // Update analytics
      this.user.postViewedAnalytics(this.training.file_id)

    //   let trainingInfo = {
    //     type: "file",
    //     fileId: this.training.file_id,
    //     employeeId: this.$store.state.user.userId,
    //     parentId: this.parentId
    //   };
    //   // this.$store.dispatch("markTrainingComplete", trainingInfo).then(() => {
    //   //   if (this.isCertified && !this.training.complete) {
    //   //     this.snackbar = true;
    //   //   }
    //   //   this.training.complete = true;
    //   // });
    //   // Update analytics
    //   // analyticService.trainingFileViewed(
    //   //   trainingInfo.fileId,
    //   //   trainingInfo.employeeId
    //   // );
    },

    errorListener() {},

    loadedMetadataListener() {
      this.duration = this.player.duration;
    },

    readyStateListener() {},

    timeUpdateListener() {
      if (Object.prototype.hasOwnProperty.call(this.training, "complete")) {
        const elapsedTime = this.$refs.videoPlayer.currentTime;
        if (
          this.training.complete === false &&
          this.needsMarkedWatched &&
          elapsedTime / this.duration > 0.8
        ) {
          this.needsMarkedWatched = false;
          this.markedVideoWatched();
        }
      }
    }
  }
};
</script>

<style scoped>
#video-page {
  position: relative;
  width: 100%;
}

.video-player {
  width: 95vw;
  height: 70vh;
  max-width: 100% !important;
  max-height: 78% !important;
  padding-bottom: 0;
}
</style>
