<template>
  <v-card color="background" flat width="500" class="mx-auto" transition="fade-transition">
    <v-card-title class="justify-center text-h4">Self Registration</v-card-title>
    <v-card-subtitle class="text-center">
      You are one step closer to enhancing the wellbeing of your organization, we just need to obtain some
      information from you first.
    </v-card-subtitle>
    <v-fade-transition mode="out-in">
      <v-card color="background" v-if="loading">
        <template v-for="n in 8">
          <v-skeleton-loader :key="n" class="my-6" type="list-item"></v-skeleton-loader>
        </template>
      </v-card>
      <v-card-text v-else>
        <validation-observer v-slot="{ invalid }">
          <v-form @submit.prevent="onSubmit">
            <flx-input
                v-for="(field, index) in formFields"
                :key="index"
                :input="field"
            >
            </flx-input>
            <v-btn color="mainMediumBlue" type="submit" :disabled="invalid">Submit</v-btn>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-fade-transition>
  </v-card>
</template>

<script>
// import { mapGetters } from "vuex";
import flxInput from '@/components/flxInput'
import {ValidationObserver} from "vee-validate"
import User from "@/services/User";

export default {
  name: "SelfRegistration",
  components: {
    flxInput,
    ValidationObserver
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.user
      },
      set(newUser) {
        return newUser
      }
    },
    setMenuImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "50";
        case "md":
          return "75";
        case "lg":
          return "100";
        case "xl":
          return "100";
      }
      return 5;
    },
  },

  data: () => ({
    formFields: [],
    models: {},
    industries: [],
    sources: [],
    loading: true
  }),

  created() {
    if (this.user === null) {
      this.user = new User()
    }
    this.buildForm()
  },

  updated() {
    // this.$refs.registrationForm.validate()
  },

  methods: {
    async buildForm() {
      await this.getAsyncMenus()
      this.formFields = this.getFormFields()
      this.loading = false
    },

    async getAsyncMenus() {
      this.industries = await this.user.industries
      this.sources = await this.user.sources
    },

    getFormFields() {
      return [
        {
          name: 'name',
          label: "Name",
          type: 'text',
          value: '',
          placeholder: "First Last",
          rules: ['required']
        },
        {
          name: 'company',
          label: "Company",
          type: 'text',
          value: '',
          placeholder: "Company Name",
          rules: ['required']
        },
        {
          name: 'industry_type',
          label: "Industry",
          type: 'select',
          value: '',
          placeholder: "Select",
          selectOptions: this.industries,
          rules: ['required']
        },
        {
          name: 'source',
          label: "Source",
          type: 'select',
          value: '',
          placeholder: "Select",
          selectOptions: this.sources,
          rules: ['required']
        },
        {
          name: 'email',
          label: "Email",
          type: 'email',
          value: '',
          placeholder: "Email Address",
          rules: ['required', 'email']
        },
        {
          name: 'phone',
          label: "Phone",
          type: 'text',
          value: '',
          placeholder: "(123) 456-7890",
          rules: ['required'] // possible phone regex , 'regex:/^[2-9]\\d{2}[2-9]\\d{2}\\d{4}$/'
        },
        {
          name: 'terms_accepted',
          label: `Terms and Conditions`,
          type: 'checkbox',
          value: '',
          rules: ['required'],
          terms: true
        }
      ]
    },

    async onSubmit() {
      const data = {}
      this.formFields.forEach(obj => {
        data[obj.name] = obj.value
      })
      await this.user.guestLogin(data)
      // console.log('guest result', JSON.parse(JSON.stringify(result)))
    },
  }
};
</script>

<style scoped>
.menu-font {
  font-family: "Nobile", sans-serif;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 3px 3px 1px -2px rgba(0, 0, 0, 0.14),
  0px 2px 2px 0px rgba(100, 152, 66, 0.3), 0px 1px 5px 0px rgba(100, 152, 66, 0.25);
}
</style>
