<template>
  <v-card flat style="background-color: transparent" class="mx-auto" width="80%">
    <v-card-title class="d-flex justify-center">Connect With R3</v-card-title>
    <v-card-subtitle class="d-flex justify-center">
      We're here to help in any way we can.
    </v-card-subtitle>
    <v-card-text>
      <v-list three-line>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>R3c Response Coordinator</v-list-item-title>
            <v-list-item-subtitle>
              <a :href="`tel:+1${phoneNumber}`">{{ phoneNumber }}</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <a :href="`mailto:${email}`">{{ email }}</a>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <a :href="`tel:+1${phoneNumber}`">
              <v-btn class="ma-2" outlined fab color="mainBlue">
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </a>
            <a :href="`mailto:${email}`">
              <v-btn class="ma-2" outlined fab color="mainBlue">
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </a>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "connectWithR3",
  data: () => ({
    phoneNumber: '888-736-0911',
    email: 'demsales@r3c.com'
  })
}
</script>

<style scoped>

</style>