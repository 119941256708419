<template>
  <v-container>
    <v-row v-if="showReport" dense>
      <v-col cols="12" md="7" lg="8" order="2" order-md="1">
        <v-card tile min-height="100%" width="100%">
          <v-card-title class="d-flex justify-center">Incident Report</v-card-title>
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="reportData"
                :items-per-page="5"
                :sort-by="['incident_percentage']"
                :sort-desc="['true']"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="4" order="1" order-md="2">
        <v-card tile width="100%" height="100%" class="fill-height">
          <pie-chart style="max-height: 30vh; max-width: 30vh; margin-left: auto; margin-right: auto;" :chart-data="chartData" />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card flat width="80%" min-height="51vh" class="mx-auto">
        <v-card-title class="d-flex justify-center">
          No Incident report data found.
        </v-card-title>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import PieChart from "../charts/pieChart";

export default {
  name: "incidentReport",
  components: {
    PieChart
  },
  props: {
    headers: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },

    reportData: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },

    chartData: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },

  computed: {
    showReport() {
      return this.reportData.length > 0
    }
  }
}
</script>

<style scoped>

</style>