import Vue from "vue";
import Vuex from "vuex";
import User from "../services/User";
// import router from "../router";
// import Communication from "../services/Communication.js"
// import User from "@/services/User";

Vue.use(Vuex);
Vue.use(require('vue-cookies'))

export default new Vuex.Store({
    state: {
        user: null,
        R3SessionToken: '',
        R3RefreshToken: '',
        robinToken: '',
        storedImage: '',
        storedSearchResults: {},
        storedMaterials: [],
        lastProduct: {},
        serviceLogisticsData: [],
        serviceLogisticsClients: [],
        lastIncidentList: [],
        lastIncident: {},
        lastIncidentTab: 0
    },
    mutations: {
        SET_USER(state, user) {
            Vue.set(state, 'user', user)
        },
        LOGOUT(state) {
            Vue.set(state, 'user', null)
            Vue.set(state, 'R3SessionToken', '')
            Vue.set(state, 'R3RefreshToken', '')
        },
        SET_R3SESSION_TOKEN(state, token) {
            Vue.set(state, 'R3SessionToken', token)
        },
        SET_R3REFRESH_TOKEN(state, token) {
            Vue.set(state, 'R3RefreshToken', token)
        },
        SET_ROBIN_TOKEN(state, token) {
            Vue.set(state, 'robinToken', token)
        },
        SET_STORED_IMAGE(state, string) {
            Vue.set(state, 'storedImage', string)
        },
        SET_STORED_SEARCH_RESULTS(state, results) {
            Vue.set(state, 'storedSearchResults', results)
        },
        SET_STORED_MATERIALS(state, materials) {
            Vue.set(state, 'storedMaterials', materials)
        },
        SET_LAST_PRODUCT(state, product) {
            Vue.set(state, 'lastProduct', product)
        },
        SET_LAST_INCIDENT_LIST(state, incidentList) {
            Vue.set(state, 'lastIncidentList', incidentList)
        },
        SET_LAST_INCIDENT(state, incident) {
            Vue.set(state, 'lastIncident', incident)
        },
        SET_LAST_INCIDENT_TAB(state, tab) {
            Vue.set(state, 'lastIncidentTab', tab)
        },
        SET_SERVICE_LOGISTICS_DATA(state, data) {
            Vue.set(state, 'serviceLogisticsData', data)
        },
        SET_SERVICE_LOGISTICS_CLIENTS(state, data) {
            Vue.set(state, 'serviceLogisticsClients', data)
        }
    },
    actions: {
        logout({commit}) {
            commit("LOGOUT");
        },
        storeUser({commit}, user) {
            commit('SET_USER', user)
        },
        callbackSuccessful({dispatch}, hash) {
            dispatch('storeUser', new User())
            dispatch('updateOauthWithHash', hash)
        },
        robinCallbackSuccessful({ commit, dispatch }, hash) {
            dispatch('storeUser', new User(window.$cookies.get('user')))
            commit('SET_STORED_IMAGE', window.$cookies.get('robinImage'))
            dispatch('updateRobinOauth', hash).then()
            window.$cookies.remove('user')
            window.$cookies.remove('robinImage')
        },
        updateRobinOauth({state, commit}, hash) {
            return new Promise((resolve, reject) => {
                return state.user.handleRobinCallback(hash)
                    .then(response => {
                        commit('SET_ROBIN_TOKEN', response.data.data.attributes.access_token)
                        state.user.runLevel3Robin()
                        resolve()
                    })
                    .catch(err => {
                        console.log('Caught error in updateRobinOauth', err)
                        reject()
                    })
            })
        },
        setStoredBackgroundImage({commit}, backgroundImage = '') {
            // console.log('setting stored background image', backgroundImage)
            commit("SET_STORED_IMAGE", backgroundImage)
        },
        setStoredSearchResults({commit}, searchResults = {}) {
            commit('SET_STORED_SEARCH_RESULTS', searchResults)
        },
        setStoredMaterials({commit}, materials = []) {
            commit('SET_STORED_MATERIALS', materials)
        },
        setLastProduct({commit}, product = {}) {
            commit('SET_LAST_PRODUCT', product)
        },
        setServiceLogisticsData({commit, dispatch}, data = []) {
            dispatch('serviceLogisticsClients', data)
            commit('SET_SERVICE_LOGISTICS_DATA', data)
        },
        serviceLogisticsClients({commit}, data = []) {
            const clients = []
            data.forEach(obj => {
                clients.push(obj.attributes.name)
            })
            commit('SET_SERVICE_LOGISTICS_CLIENTS', clients.sort())
        },
        setLastIncidentList({commit}, incidentList = []) {
            commit('SET_LAST_INCIDENT_LIST', incidentList)
        },
        setLastIncident({commit}, incident = {}) {
            commit('SET_LAST_INCIDENT', incident)
        },
        setLastIncidentTab({commit}, tab = 0) {
            commit('SET_LAST_INCIDENT_TAB', tab)
        },
        updateOauthWithHash({commit, state}, hash = '') {
            return new Promise((resolve, reject) => {
                return state.user.updateOauthWithHashAndGetTokens(hash)
                    .then(response => {
                        commit('SET_R3SESSION_TOKEN', response.data.data.attributes.access_token)
                        commit('SET_R3REFRESH_TOKEN', response.data.data.attributes.refresh_token)
                        state.user.login(response.data.data.attributes.access_token)
                        resolve()
                    })
                    .catch(err => {
                        console.log('Caught error in updateOauthWithHash', err)
                        reject()
                    })
            })
        },
        login({state, commit}, token) {
            const user = state.user.login(token)
            commit('SET_USER', user)
        }
    },
    getters: {
        loggedIn(state) {
            return state.user != null;
        },
        user(state) {
            return state.user;
        },
        r3SessionToken(state) {
            return state.R3SessionToken
        },
        robinToken(state) {
            return state.robinToken
        },
        storedImage(state) {
            return state.storedImage
        },
        storedSearchResults(state) {
            return state.storedSearchResults
        },
        storedMaterials(state) {
            return state.storedMaterials
        },
        lastProduct(state) {
            return state.lastProduct
        },
        serviceLogisticsData(state) {
            return state.serviceLogisticsData
        },
        serviceLogisticsClients(state) {
            return state.serviceLogisticsClients
        },
        lastIncidentList(state) {
            return state.lastIncidentList
        },
        lastIncident(state) {
            return state.lastIncident
        },
        lastIncidentTab(state) {
            return state.lastIncidentTab
        }

        // getAnnouncementUnReadCount: state => () => {
        //   return state.announcements.filter(announcement => {
        //     return !announcement.read;
        //   }).length;
        // },
        // getPendingTrainingCount: state => () => {
        //   return state.training.products.filter(product => {
        //     return !(product.status === "complete");
        //   }).length;
        // },
        // getTags: state => () => {
        //   return state.tags;
        // }
    }
});
