<template>
  <v-dialog v-model="localShow" persistent max-width="500">
    <v-card>
      <v-card-title>
        {{ questionItem.test_name }}
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-card-title>
      <v-slide-x-transition mode="out-in">
        <v-card v-if="!showFinalSlide" flat :key="currentQuestionIndex">
          <v-card-title class="customer--text">
            {{ currentQuestion.description }}
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="selectedAnswer"
                    :disabled="showResult"
                  >
                    <v-radio
                      color="customer"
                      v-for="choice in currentQuestion.choices"
                      :key="choice.question_id"
                      :label="`${choice.sequence}: ${choice.description}`"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-slide-y-transition appear mode="out-in">
                <v-row style="min-height: 13em;">
                  <v-col v-if="!showResult" align="center" justify="center">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="customer"
                      @click="checkAnswer"
                      class="my-auto"
                      :disabled="selectedAnswer === null"
                      >Submit</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col v-else>
                    <v-card flat>
                      <v-card-title>
                        {{ correct ? "Correct!" : "Incorrect." }}
                      </v-card-title>
                      <v-card-text
                        v-text="answerText"
                        color="resultColor"
                      ></v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="customer" @click="nextQuestion">Next</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-slide-y-transition>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-else flat>
          <v-card-title class="customer--text">Congratulations!</v-card-title>
          <v-card-text>This quiz has been completed and successfully recorded! You may now close this window.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="customer" @click="closeDialog" class="mb-4">Close</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-slide-x-transition>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Questions",
  props: {
    questionItem: {
      type: Object,
      required: true
    },

    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  watch: {
    show: function(val) {
      this.localShow = val;
    }
  },

  computed: {
    currentQuestion: function() {
      return this.questionItem.questions
        ? this.questionItem.questions[this.currentQuestionIndex]
        : {};
    }
  },

  data: () => ({
    localShow: false,
    currentQuestionIndex: 0,
    selectedAnswer: null,
    progress: [],
    showResult: false,
    correct: false,
    answerText: "",
    resultColor: "black",
    showFinalSlide: false
  }),

  methods: {

    checkAnswer() {
      let selectedAnswer = this.currentQuestion.choices[this.selectedAnswer];
      let correctAnswer;
      // console.log("selected answer", selectedAnswer);
      this.progress.push({
        answerId: selectedAnswer.test_choice_id,
        correct: selectedAnswer.answer
      });
      if (!selectedAnswer.answer) {
        correctAnswer = this.currentQuestion.choices.filter(
          choice => choice.answer
        )[0];
      }
      this.correct = selectedAnswer.answer;
      if (selectedAnswer.answer) {
        this.answerText = `${selectedAnswer.sequence} is the correct answer!`;
        this.resultColor = "green";
      } else {
        this.answerText = `The correct answer is ${correctAnswer.sequence}: ${correctAnswer.description}.`;
        this.resultColor = "red";
      }
      this.showResult = true;
    },

    nextQuestion() {
      this.correct = false;
      this.answerText = "";
      this.showResult = false;
      this.selectedAnswer = null;
      this.resultColor = "black";
      if (this.currentQuestionIndex + 1 < this.questionItem.questions.length) {
        this.currentQuestionIndex += 1;
      } else {
        this.$emit("questions-complete", this.questionItem, this.progress);
        this.showFinalSlide = true
      }
    },

    closeDialog() {
      this.$emit('close-test')
    }
  }
};
</script>

<style scoped></style>
