
<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins
export default {
  name: "barChart",
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    data: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },

    options: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style scoped>

</style>