<template>
  <v-card flat tile color="background" :width="setMenuWidth" class="mx-auto">
    <v-card-title class="justify-center text-h4">{{ group.title }}</v-card-title>
    <v-card-subtitle class="text-center">
      {{ group.subTitle }}
    </v-card-subtitle>
    <v-container>
      <v-row class="d-flex justify-center">
        <v-col cols="6" :md="getMdCols" v-for="(item, index) in menuItems" :key="index">
          <menu-card :height="setMenuImageHeight" :menuItem="item" @itemClicked="navigateTo(item)"></menu-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<v-btn class="delete">
<v-icon medium class="delete-icon">mdi-delete</v-icon>
<p>Delete</p>
</v-btn>

<script>
import {mapGetters} from "vuex";
import menuCard from "@/components/menuCard";
import MenuGroup from "@/js/MenuGroup";

export default {
  name: "Menu",
  components: {
    menuCard
  },
  data: () => ({
    loginModal: true,
    micColor: "secondary",
    username: null,
    trainingCount: 0,
    group: {},
    menuItems: {},
    menuWidth: 600
  }),

  computed: {
    ...mapGetters(['user']),
    setMenuImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "175";
        case "sm":
          return "225";
        case "md":
          return "250";
        case "lg":
          return "250";
        case "xl":
          return "275";
      }
      return 5;
    },

    setMenuWidth() {
      // if (this.getMdCols === 6) { // only two columns
      //   return 600
      // }
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return this.getMdCols === 6 ? "600" : "800";
        case "lg":
          return this.getMdCols === 6 ? "700" : "850";
        case "xl":
          return this.getMdCols === 6 ? "750" : "900";
      }
      return 600;
    },

    getMdCols() {
      // console.log('menu items', this.menuItems)
      // if (this.menuItems.length < 5) {
      //   return 6
      // }
      return 4
    }
  },

  mounted() {
    this.$store.dispatch('setLastIncidentList') // Clear list

    this.group = new MenuGroup(this.user.level).getGroup()
    this.menuItems = this.group.menuItems
  },

  updated() {
  },

  methods: {

    async navigateTo(menuItem) {
      const path = menuItem.routeName
      let props
      if (menuItem.call) {
        let call = menuItem.call
        if (menuItem.title.localeCompare("Communication From Leadership") === 0) {
          call += this.user.caseCode
        } else if (menuItem.title.localeCompare("Provide Support Feedback") === 0) {
          call += this.user.uuid
        }
        props = await this.user.getProps(call)
        // console.log('props', props)
      }
      if (path.localeCompare("requestService") === 0) {
        window.open(`${process.env.VUE_APP_R3_BASE_URL}/case_intake/new`, '_blank')
      } else if (path.localeCompare('Robin') === 0 && this.user.level === 3) {
        const routeData = this.$router.resolve({
          name: 'Robin',
          query: {
            title: menuItem.routeName,
            caseCode: this.user.caseCode,
            deviceId: this.user.deviceId
          }
        })
        window.open(routeData.href, '_blank')
      } else if (path !== this.$route.name && path !== '#') {
        const params = this.buildParams(menuItem, props)
        // console.log('params', params)
        await this.$router.push({
          name: path,
          params: params
        })
      }
    },

    buildParams(menuItem, props = null) {
      let result = {
        backgroundImage: menuItem.image,
        title: menuItem.title
      }
      this.$store.dispatch('setStoredBackgroundImage', menuItem.image)

      if (props === null && "Contact Us".localeCompare(menuItem.routeName) === 0) {
        // guest level Connect with R3
        props = this.guestLevelConnectWithR3()
      }

      if (props) {
        if ("Readiness Assessment".localeCompare(menuItem.title) === 0) {
          // Handle one-off independent survey not part of a standard product package.
          result['trainingItem'] = {
            name: menuItem.title,
            entity_type: 'product',
            surveys: [props],
            documents: [],
            videos: [],
            questions: [],
            standAloneSurvey: true
          }
        } else if ("Provide Support Feedback".localeCompare(menuItem.title) === 0) {
          result['trainingItem'] = {
            name: menuItem.title,
            entity_type: 'product',
            surveys: this.buildSupportSurveys(props.surveys),
            documents: [],
            videos: [],
            questions: [],
            standAloneSurvey: true
          }
        } else if ("Search Results".localeCompare(menuItem.routeName) === 0) {
          result['search'] = props.products
        } else if ("Contact Us".localeCompare(menuItem.routeName) === 0) {
          result['contacts'] = props
        } else {
          result['trainingItem'] = props
        }
      }
      return result
    },

    buildSupportSurveys(surveys) {
      for (const survey in surveys) {
        surveys[survey]['survey_name'] = surveys[survey].name
      }
      return surveys
    },

    /**
     * @description
     * Configure guest level Connect With R3 contact information here.
     * @returns {Object} - Guest level Connect With R3 information.
     */
    guestLevelConnectWithR3() {
      return {
        "contacts": [
          {
            "group": "R3 Continuum",
            "name": "Account Manager",
            "phone_number": "616-965-2415",
            // "email": "response.center@r3c.com"
          }
        ],
        // "websites": [
        //   {
        //     "group": "R3 Continuum",
        //     "web_url": "https://www.r3c.com",
        //     "description": "Main Website"
        //   }
        // ]
      }
    }
  }
};
</script>

<style scoped>
.menu-font {
  font-family: "Nobile", sans-serif;
}
</style>
