// Core installs
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from "./vuex/store";
import router from "./router";

// Plugins
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

// Vee Validate
import { extend } from "vee-validate";
// Extend VeeValidate3 rules here. Full list available at
// @link: https://vee-validate.logaretm.com/v3/guide/rules.html
import {required, email, regex, min} from "vee-validate/dist/rules";
extend('required', required)
extend('email', email)
extend('regex', regex)
extend('min', min)

Vue.config.productionTip = false
Vue.use(VueMoment, { moment })

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
