// default guest structure
// need menu items buildout
// menu title

// noinspection JSUnusedGlobalSymbols
export default class MenuGroup {
    Group = {
        title: '',
        subTitle: '',
        menuItems: []
    }

    /**
     * Pre-built cards
     * @example = {
     *    title: title of card,
     *    image: background image on the card,
     *    routeName: Destination page. options: Product Page, Search Results, Materials,
     *    imagePosition (optional): nudge the background image to show a specific portion:
     *                              top, right, bottom, left, center (default),
     *    call (optional): api URL if details need to be loaded for the menu item
     * }
     */

    ReadinessAssessment = {
        title: 'Readiness Assessment',
        image: 'menuImages/downward-spiral.png',
        routeName: 'Product Page',
        imagePosition: 'top',
        call: 'api/surveys/get_survey/6'
    }
    EducationalSupport = {
        title: 'Educational Support',
        image: 'menuImages/paper-stack.png',
        routeName: 'Materials'
    }
    TailoredStrategies = { // guest level robin
        title: 'Tailored Strategies',
        image: 'menuImages/desk-with-charts.png',
        routeName: '#', // 'Robin', // Removed Robin May 26, 2023 @jemdehay @card: r3sil-139
        imagePosition: 'right'
    }
    ConnectWithR3 = { // guest level
        title: 'Connect With R3',
        image: 'menuImages/path-to-enlightenment.png',
        routeName: 'Contact Us'
    }
    Reporting = {
        title: 'Reporting',
        image: 'menuImages/chart.png',
        routeName: 'Reporting'
    }
    ChatWithRobin = {
        title: 'Chat With Robin',
        image: 'menuImages/new-robin-small.png',
        routeName: '#', // 'Robin', // Removed Robin May 26, 2023 @jemdehay @card: r3sil-139
        imagePosition: 'top'
    }
    RequestService = {
        title: 'Request Service',
        image: 'menuImages/straighten-thoughts.png',
        routeName: 'requestService',
    }
    ServiceLogistics = {
        title: 'Service Logistics',
        image: 'menuImages/brain-blue.png',
        routeName: 'Incident List',
    }
    LeadershipSupport = {
        title: 'Communication From Leadership',
        image: 'menuImages/high-five.png',
        routeName: 'Search Results',
        imagePosition: 'top',
        call: 'api/assets/r3_incident_training/internal/level3/'
    }
    ProvideSupportFeedback = {
        title: 'Provide Support Feedback',
        image: 'menuImages/helping-hand.png',
        routeName: 'Product Page',
        call: 'api/surveys/find/r3survey-3/'
    }
    SupportMaterials = {
        title: 'Support Materials',
        image: 'menuImages/book-stack.png',
        routeName: 'Materials'
    }
    ContactInfo = {
        title: 'Contact Information',
        image: 'menuImages/hand-raised-desk.png',
        routeName: 'Contact Us',
        call: 'api/contacts/all',
        imagePosition: 'right'
    }

    /**
     * @description = Build dynamic menu items based on the level of viewer
     * @param {Number} type - One of: 0 - Guest | 1 - Level1 | 2 - Level2 | 3 - Level3
     */
    constructor(type) {
        switch (type) {
            case 1:
                this.buildLevel1()
                break
            case 2:
                this.buildLevel2()
                break
            case 3:
                this.buildLevel3()
                break
            default:
                this.buildGuest()
                break
        }
    }

    getGroup() {
        return this.Group
    }

    buildGuest() {
        this.Group.title = 'Readiness Tools'
        this.Group.subTitle = 'Ensure your organization is ready and has the tools needed for protecting the ' +
            'wellbeing of your people in the wake of a workplace disruption'
        this.Group.menuItems = [
            this.ReadinessAssessment,
            this.EducationalSupport,
            // this.TailoredStrategies, // Removed Robin May 26, 2023 @jemdehay @card: r3sil-139
            this.ConnectWithR3
        ]
    }

    // Most access
    buildLevel1() {
        this.Group.title = 'Main Menu'
        this.Group.subTitle = ''
        this.Group.menuItems = [
            this.RequestService,
            this.ServiceLogistics,
            this.SupportMaterials,
            this.Reporting,
            this.ContactInfo,
            // this.ChatWithRobin // Removed Robin May 26, 2023 @jemdehay @card: r3sil-139
        ]
    }

    // Middle Access
    buildLevel2() {
        this.Group.title = 'Main Menu'
        this.Group.subTitle = ''
        this.Group.menuItems = [
            this.ServiceLogistics,
            this.SupportMaterials,
            this.ContactInfo,
            // this.ChatWithRobin // Removed Robin May 26, 2023 @jemdehay @card: r3sil-139
        ]
    }

    // Least Access
    buildLevel3() {
        this.Group.title = 'Main Menu'
        this.Group.subTitle = ''
        this.Group.menuItems = [
            this.SupportMaterials,
            this.LeadershipSupport,
            // this.ChatWithRobin, // Removed Robin May 26, 2023 @jemdehay @card: r3sil-139
            this.ProvideSupportFeedback
        ]
    }
}