<template>
  <v-card
      style="cursor: pointer; max-width: 120%;"
      tile
      :height="height"
      :width="height"
      class="mx-auto"
      @click="$emit('itemClicked', this)"
  >
    <v-img :src="imageSource" aspect-ratio="1" :position="getPosition">
      <v-container fluid class="fill-height">
        <v-card tile id="center-card" width="70%" height="40%" class="mx-auto">
          <v-card-title
              :style="{ fontSize: titleSize, backgroundColor: backgroundColor }"
              class="fill-height text-break"
              align="center"
          >
            <v-spacer></v-spacer>
            <div class="w-90 mx-auto" style="line-height: 1.25;">
              {{ title }}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
        </v-card>
      </v-container>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "menuCard",
  components: {},
  props: {
    menuItem: {
      type: Object,
      required: true
    },

    height: {
      type: String,
      required: false,
      default: "100"
    }
  },
  computed: {
    imageSource: function () {
      const image = this.menuItem.image ? this.menuItem.image : 'logo.png'
      return require(`../assets/${image}`)
    },

    getPosition: function () {
      return this.menuItem.imagePosition ? this.menuItem.imagePosition : 'center center'
    },

    title: function () {
      return this.menuItem.title || ''
    },

    titleSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '0.8rem !important'
        case 'sm':
          return '0.9rem !important'
        case 'md':
        case 'lg':
        case 'xl':
          return '1rem !important'
      }
      // console.log('titleSize', val)
      return '1rem'
    }
  },
  watch: {},

  data: () => ({
    backgroundColor: 'mainGrey'
  }),

  mounted() {
  },

  methods: {}
}
</script>

<style scoped>

#center-card {
  background-color: rgba(230, 231, 232, 0.875);
}

</style>