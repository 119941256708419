import Vue from "vue";
import VueRouter from "vue-router";
import store from "./vuex/store";
import NotFound from "./views/NotFound";
import Menu from "./views/Menu.vue";
import Login from "./views/Login";
import SelfRegistration from "./views/SelfRegistration";
import Scanner from "./views/Scanner";
import IncidentList from "./views/IncidentList";
import Incident from "./views/Incident";
import Materials from "./views/Materials";
import Reporting from "./views/Reporting";
import Contact from "./views/Contact";
import ProductPage from "./views/ProductPage";
import Callback from "./views/Callback";
import SearchResults from "./views/SearchResults";
import Robin from "./views/Robin";
import User from "./services/User";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/login",
            name: "Login",
            component: Login,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/self-registration",
            name: "Self Registration",
            component: SelfRegistration,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/scanner",
            name: "Scanner",
            component: Scanner,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/incident-list",
            name: "Incident List",
            component: IncidentList,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/incident",
            name: "Incident",
            component: Incident,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/materials",
            name: "Materials",
            component: Materials,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/product",
            name: "Product Page",
            component: ProductPage,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/reporting",
            name: "Reporting",
            component: Reporting,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/robin",
            name: "Robin",
            component: Robin,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                // console.log('before enter robin', routeTo, routeFrom, next)
                if (store.getters.user === null && routeTo.query.title === 'Robin') {
                    window.$cookies.set('robinLogin', true, 60)
                    const params = {
                        level: 3,
                        caseCode: routeTo.query.caseCode,
                        deviceId: routeTo.query.deviceId
                    }
                    const user = new User(params)
                    store.dispatch('storeUser', user)
                        .then(() => window.$cookies.set('user', user ))
                        .then(() => next())
                } else {
                    next();
                }
                next()
            }
        },
        {
            path: "/contact",
            name: "Contact Us",
            component: Contact,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/results",
            name: "Search Results",
            component: SearchResults,
            props: true,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/callback",
            name: "Callback",
            component: Callback,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/",
            name: "Menu",
            component: Menu,
            beforeEnter(routeTo, routeFrom, next) {
                next();
            }
        },
        {
            path: "/404",
            name: "404",
            component: NotFound

        }
    ],
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

router.beforeEach((routeTo, routeFrom, next) => {
    // console.log('vue router to | from | next', routeTo, routeFrom, next)
    document.title = `R3SILIENCY | ${routeTo.name}`;
    const loggedOutDestination = ['Login', 'Self Registration', 'Scanner', 'Callback'].includes(routeTo.name)
    // next();
    // console.log('store', store)
    if (routeTo.name === 'Robin') {
        next()
    } else if ((store.getters.user === null || !store.getters.user.loggedIn) && !loggedOutDestination) {
        next({name: "Login"});
    } else if (store.getters.user && store.getters.user.loggedIn && loggedOutDestination) {
        next({name: "Menu"})
    } else {
        next();
    }
});

router.afterEach(() => {
});

export default router;
