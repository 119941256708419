<template>
  <div>
    <styled-background :image="currentBackgroundImage" opacity="35%"/>
    <v-card tile width="80%" class="mx-auto">
      <v-card-title class="d-flex justify-center">Reporting</v-card-title>
      <v-card-subtitle class="d-flex justify-center">
        Select date range and client to generate report.
      </v-card-subtitle>
      <!-- Search Filters -->
      <validation-observer v-slot="{ invalid }">
        <v-form ref="filterForm" @submit.prevent="generateReport">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="6" v-for="(item, index) in dateFilters" :key="index">
                <flx-input :input="item"/>
              </v-col>
              <v-col cols="12">
                <flx-input :input="clientFilter"/>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-btn
                  type="submit"
                  color="mainBlue"
                  style="color: white;"
                  :loading="loading"
                  :disabled="invalid"
              >Search</v-btn>
              <v-spacer />
              <p class="red--text my-auto">{{ errors }}</p>
              <v-spacer />
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>

      <!-- Incident Report -->
      <template v-if="showReport">
        <v-tabs
            v-model="tab"
            fixed-tabs
            center-active
            show-arrows
            color="mainBlue"
            background-color="mainGrey"
        >
          <v-tabs-slider color="mainBlue"></v-tabs-slider>
          <v-tab v-for="item in tabItems" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in tabItems" :key="item">
            <incident-report
                v-if="index === 0"
                :headers="incidentReportHeaders"
                :report-data="incidentReportData"
                :chart-data="incidentChartData"
            />
            <utilization-report
                v-if="index === 1"
                :headers="utilizationReportHeaders"
                :report-data="utilizationReportData"
                :chart-data="utilizationChartData"
            />
            <billable-hours-report
                v-if="index === 2"
                :headers="billableHoursReportHeaders"
                :report-data="billableHoursReportData"
                :chart-data="billableHoursChartData"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate"

import FlxInput from "../components/flxInput";
import StyledBackground from "../components/styledBackground";
import IncidentReport from "../components/reports/incidentReport";
import UtilizationReport from "../components/reports/utilizationReport";
import BillableHoursReport from "../components/reports/billableHoursReport";

export default {
  name: "Reporting",
  components: {
    BillableHoursReport,
    UtilizationReport,
    IncidentReport,
    FlxInput,
    StyledBackground,
    ValidationObserver,
  },
  props: {
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    ...mapGetters(['user', 'serviceLogisticsData', 'serviceLogisticsClients']),
    currentBackgroundImage() {
      return this.backgroundImage === '' ? this.storedImage : this.backgroundImage
    },
  },

  data: () => ({
    dateFilters: [],
    clientFilter: {},
    dateResults: {},
    loading: false,
    showReport: false,
    errors: '',
    tab: null,
    tabItems: ['Incidents', 'Utilization', 'Billable Hours'],
    incidentReportData: [],
    incidentReportHeaders: [],
    incidentChartData: {},
    utilizationReportData: [],
    utilizationReportHeaders: [],
    utilizationChartData: {},
    billableHoursReportHeaders: [],
    billableHoursReportData: [],
    billableHoursChartData: {},
    colorPalette: []
  }),

  mounted() {
    this.dateFilters = [
      {
        name: 'startDate',
        label: 'Start Date',
        type: 'date',
        value: '',
        rules: ['required']
      },
      {
        name: 'endDate',
        label: 'End Date',
        type: 'date',
        value: '',
        rules: ['required']
      },
    ]
    this.clientFilter = {
      name: 'client',
      label: 'Client',
      type: 'combo',
      value: '',
      selectOptions: this.serviceLogisticsClients
    }
    this.incidentReportHeaders = [
      { text: 'Incident Types', value: 'incident' },
      { text: 'Numbers', value: 'incident_count' },
      { text: 'Percentage', value: 'incident_percentage' },
    ]
    this.utilizationReportHeaders = [
      { text: 'Groups', value: 'groups' },
      { text: 'Group Participants', value: 'group_participants' },
      { text: 'One on One', value: 'one_on_one' },
      { text: 'Management Consultations', value: 'mgt_consultations' },
    ]
    this.billableHoursReportHeaders = [
      { text: 'Billed', value: 'billed' },
      { text: 'Estimated', value: 'estimated' }
    ]
    this.colorPalette = [
        // crisis response colors
        '#5e005e', '#ab2f52', '#e55d4a', '#ffaf53',
        // protective services colors
        '#0a3a4a', '#196674', '#33a6b2', '#d0e64b',
        // evaluations colors
        '#000f5e', '#6e177d', '#1fb3cf', '#ccbbff',
        // executive optimization colors
        '#002b4a', '#708891', '#ab4b4c', '#d3dee1',
        // duplicate crisis response colors
        '#5e005e', '#ab2f52', '#e55d4a', '#ffaf53',
        // duplicate protective services colors
        '#0a3a4a', '#196674', '#33a6b2', '#d0e64b',
    ]
  },

  updated() {
  },

  methods: {

    async generateReport() {
      this.errors = ''
      this.showReport = false
      this.loading = true
      // get date filters
      for (const filter in this.dateFilters) {
        this.dateResults[this.dateFilters[filter].name] = this.dateFilters[filter].value
      }
      const startDate = this.$moment(this.dateResults?.startDate)
      const endDate = this.$moment(this.dateResults?.endDate)
      let clientId = null
      if (this.clientFilter.value) {
        clientId = this.serviceLogisticsData.filter(obj => {
          return obj.attributes.name === this.clientFilter.value
        })[0].id
      }
      if (startDate.isValid() && endDate.isValid() && startDate.isBefore(endDate)) {
        await this.buildIncidentReport(startDate, endDate, clientId)
        await this.buildUtilizationReport(startDate, endDate, clientId)
        await this.buildBillableHoursReport(startDate, endDate, clientId)
        if (this.incidentReportData.length > 0 ||
            this.utilizationReportData > 0 ||
            this.billableHoursReportData > 0) {
          this.showReport = true
        } else {
          this.errors = 'No data from report. Try a different search.'
        }
      } else {
        this.errors = 'Invalid dates'
      }
      this.loading = false
    },

    async buildIncidentReport(startDate, endDate, clientId = null) {
      this.incidentReportData = []
      this.incidentChartData = {}
      const incidentReport =
          await this.user.getReportIncidents(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), clientId)
      if (incidentReport.length > 0) {
        const labelCollection = []
        const dataPoints = []
        // Populate Data
        incidentReport.forEach(obj => {
          this.incidentReportData.push(obj.attributes)
          labelCollection.push(obj.attributes.incident)
          dataPoints.push(obj.attributes.incident_count)})
        // build pie chart
        this.incidentChartData = {
          labels: labelCollection,
          datasets: [
            {
              label: 'Incidents',
              data: dataPoints,
              backgroundColor: this.colorPalette
            }
          ]
        }
      }
    },

    async buildUtilizationReport(startDate, endDate, clientId = null) {
      this.utilizationReportData = []
      this.utilizationChartData = {}
      const utilizationReport =
          await this.user.getReportUtilization(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), clientId)
      if (utilizationReport.length > 0) {
        let groups = 0
        let groupParticipants = 0
        let oneOnOnes = 0
        let managementConsultations = 0
        // Populate Data
        utilizationReport.forEach(obj => {
          this.utilizationReportData.push(obj.attributes)
          if (obj.attributes.groups) {
            groups += obj.attributes.groups
          }
          if (obj.attributes.group_participants) {
            groupParticipants += obj.attributes.group_participants
          }
          if (obj.attributes.one_on_one) {
            oneOnOnes += obj.attributes.one_on_one
          }
          if (obj.attributes.mgt_consultations) {
            managementConsultations += obj.attributes.mgt_consultations
          }
        })
        // Build bar chart
        this.utilizationChartData = {
          // labels: 'Utilization',
          labels: this.utilizationReportHeaders.map(r => r.text),
          datasets: [
            {
              label: 'Utilization',
              data: [groups, groupParticipants, oneOnOnes, managementConsultations],
              backgroundColor: this.colorPalette
            }
          ]
        }
      }
    },

    async buildBillableHoursReport(startDate, endDate, clientId = null) {
      this.billableHoursReportData = []
      this.billableHoursChartData = {}
      const billableHoursReport =
          await this.user.getReportBillableHours(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), clientId)
      if (billableHoursReport.length > 0) {
        let billed = 0
        let estimated = 0
        // Populate Data
        billableHoursReport.forEach(obj => {
          this.billableHoursReportData.push(obj.attributes)
          if (obj.attributes.billed) {
            billed += parseFloat(obj.attributes.billed)
          }
          if (obj.attributes.estimated) {
            estimated += parseFloat(obj.attributes.estimated)
          }
        })
        // Build bar chart
        this.billableHoursChartData = {
          labels: this.billableHoursReportHeaders.map(r => r.text),
          datasets: [
            {
              label: 'Billable Hours',
              data: [billed, estimated],
              backgroundColor: [this.colorPalette[4], this.colorPalette[7]]
            }
          ]
        }
      }
    }

  }
};
</script>

<style scoped>
</style>
